@charset "UTF-8";
h1, h2, .accordion-header .btn-link .accordion-header-text, h3, h4, h5, h6,
.h1, .h2, .table-bordered thead th, .h3, .h4, .h5, .h6 {
  margin-bottom: 0;
  font-family: "GT America", sans-serif;
  font-weight: 500;
  line-height: 1.2;
  color: #000000;
}

h1, .h1 {
  font-size: 1.875rem;
}

h2, .accordion-header .btn-link .accordion-header-text, .h2, .table-bordered thead th {
  font-size: 1.125rem;
}

h3, .h3 {
  font-size: 1rem;
}

h4, .h4 {
  font-size: 0.75rem;
}

h5, .h5 {
  font-size: 0.75rem;
}

h6, .h6 {
  font-size: 0.75rem;
}

.lead {
  font-size: 1.5625rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  border: 0;
  border-top: 2px solid #000000;
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 0.625rem;
  font-size: 1.5625rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 2px solid #dee2e6;
  border-radius: 0;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.3125rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

.table {
  width: 100%;
  margin-bottom: 0.625rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 2px solid #000000;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 4px solid #000000;
}
.table tbody + tbody {
  border-top: 4px solid #000000;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 2px solid #000000;
}
.table-bordered th,
.table-bordered td {
  border: 2px solid #000000;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 4px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #dfd8cd;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #c4b6a3;
}

.table-hover .table-primary:hover {
  background-color: #d5ccbd;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #d5ccbd;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ebb8b8;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #d97b7b;
}

.table-hover .table-danger:hover {
  background-color: #e5a4a4;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #e5a4a4;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f9f8f7;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f4f3ef;
}

.table-hover .table-light:hover {
  background-color: #eeebe8;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #eeebe8;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #f2ede7;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #e6ded2;
}

.table-hover .table-dark:hover {
  background-color: #e9e1d6;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #e9e1d6;
}

.table-bronze,
.table-bronze > th,
.table-bronze > td {
  background-color: #dfd8cd;
}
.table-bronze th,
.table-bronze td,
.table-bronze thead th,
.table-bronze tbody + tbody {
  border-color: #c4b6a3;
}

.table-hover .table-bronze:hover {
  background-color: #d5ccbd;
}
.table-hover .table-bronze:hover > td,
.table-hover .table-bronze:hover > th {
  background-color: #d5ccbd;
}

.table-beige-dark,
.table-beige-dark > th,
.table-beige-dark > td {
  background-color: #f2ede7;
}
.table-beige-dark th,
.table-beige-dark td,
.table-beige-dark thead th,
.table-beige-dark tbody + tbody {
  border-color: #e6ded2;
}

.table-hover .table-beige-dark:hover {
  background-color: #e9e1d6;
}
.table-hover .table-beige-dark:hover > td,
.table-hover .table-beige-dark:hover > th {
  background-color: #e9e1d6;
}

.table-beige-light,
.table-beige-light > th,
.table-beige-light > td {
  background-color: #f9f8f7;
}
.table-beige-light th,
.table-beige-light td,
.table-beige-light thead th,
.table-beige-light tbody + tbody {
  border-color: #f4f3ef;
}

.table-hover .table-beige-light:hover {
  background-color: #eeebe8;
}
.table-hover .table-beige-light:hover > td,
.table-hover .table-beige-light:hover > th {
  background-color: #eeebe8;
}

.table-mint,
.table-mint > th,
.table-mint > td {
  background-color: #eef2e8;
}
.table-mint th,
.table-mint td,
.table-mint thead th,
.table-mint tbody + tbody {
  border-color: #e0e6d4;
}

.table-hover .table-mint:hover {
  background-color: #e2e9d8;
}
.table-hover .table-mint:hover > td,
.table-hover .table-mint:hover > th {
  background-color: #e2e9d8;
}

.table-red,
.table-red > th,
.table-red > td {
  background-color: #ebb8b8;
}
.table-red th,
.table-red td,
.table-red thead th,
.table-red tbody + tbody {
  border-color: #d97b7b;
}

.table-hover .table-red:hover {
  background-color: #e5a4a4;
}
.table-hover .table-red:hover > td,
.table-hover .table-red:hover > th {
  background-color: #e5a4a4;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #ffffff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #000000;
}

.table-dark {
  color: #ffffff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 324.98px) {
  .table-responsive-xs {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xs > .table-bordered {
    border: 0;
  }
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1024.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1398.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.13158em + 0.75rem);
  padding: 0.375rem 0;
  font-family: "Kokoro", serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.13158;
  color: #000000;
  background-color: transparent;
  background-clip: padding-box;
  border: 2px solid #000000;
  border-radius: 0;
  transition: background-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000000;
}
.form-control:focus {
  color: #000000;
  background-color: transparent;
  border-color: #000000;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(141, 114, 78, 0.25);
}
.form-control::placeholder {
  color: #000000;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #C3CFAC;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #000000;
  background-color: transparent;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 2px);
  padding-bottom: calc(0.375rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.13158;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 2px);
  padding-bottom: calc(0.5rem + 2px);
  font-size: 1.5625rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 2px);
  padding-bottom: calc(0.25rem + 2px);
  font-size: 1.09375rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1.25rem;
  line-height: 1.13158;
  color: #000000;
  background-color: transparent;
  border: solid transparent;
  border-width: 2px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem);
  padding: 0.25rem 0.5rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem);
  padding: 0.5rem 0;
  font-size: 1.5625rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 45px;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 8px;
  padding-left: 8px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 1.09375rem;
  line-height: 1.75;
  color: #ffffff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 8px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.13158em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.282895em + 0.1875rem) center;
  background-size: calc(0.56579em + 0.375rem) calc(0.56579em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.13158em + 0.75rem);
  background-position: top calc(0.282895em + 0.1875rem) right calc(0.282895em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 1.5625rem);
  background: url("/typo3conf/ext/wvier_speciality/Resources/Public/Assets/Production/Images/Theme/small-arrow-black.svg"), transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1rem/calc(0.56579em + 0.375rem) calc(0.56579em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #b60202;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 1.09375rem;
  line-height: 1.75;
  color: #ffffff;
  background-color: rgba(182, 2, 2, 0.9);
  border-radius: 0;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 8px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #b60202;
  padding-right: calc(1.13158em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23b60202' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23b60202' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.282895em + 0.1875rem) center;
  background-size: calc(0.56579em + 0.375rem) calc(0.56579em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #b60202;
  box-shadow: 0 0 0 0.2rem rgba(182, 2, 2, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.13158em + 0.75rem);
  background-position: top calc(0.282895em + 0.1875rem) right calc(0.282895em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #b60202;
  padding-right: calc(0.75em + 1.5625rem);
  background: url("/typo3conf/ext/wvier_speciality/Resources/Public/Assets/Production/Images/Theme/small-arrow-black.svg"), transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23b60202' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23b60202' stroke='none'/%3e%3c/svg%3e") center right 1rem/calc(0.56579em + 0.375rem) calc(0.56579em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #b60202;
  box-shadow: 0 0 0 0.2rem rgba(182, 2, 2, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #b60202;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #b60202;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #b60202;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e80303;
  background-color: #e80303;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(182, 2, 2, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #b60202;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #b60202;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #b60202;
  box-shadow: 0 0 0 0.2rem rgba(182, 2, 2, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  text-decoration: none;
  background-color: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
}
.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #8D724E;
  border-color: #8D724E;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -2px;
  border-top-width: 2px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 2px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -2px;
  border-left-width: 2px;
}

@media (min-width: 325px) {
  .list-group-horizontal-xs {
    flex-direction: row;
  }
  .list-group-horizontal-xs > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xs > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xs > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xs > .list-group-item + .list-group-item {
    border-top-width: 2px;
    border-left-width: 0;
  }
  .list-group-horizontal-xs > .list-group-item + .list-group-item.active {
    margin-left: -2px;
    border-left-width: 2px;
  }
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 2px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -2px;
    border-left-width: 2px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 2px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -2px;
    border-left-width: 2px;
  }
}
@media (min-width: 1025px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 2px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -2px;
    border-left-width: 2px;
  }
}
@media (min-width: 1399px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 2px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -2px;
    border-left-width: 2px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 2px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #493b29;
  background-color: #dfd8cd;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #493b29;
  background-color: #d5ccbd;
}
.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #493b29;
  border-color: #493b29;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #5f0101;
  background-color: #ebb8b8;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #5f0101;
  background-color: #e5a4a4;
}
.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #5f0101;
  border-color: #5f0101;
}

.list-group-item-light {
  color: #7a7875;
  background-color: #f9f8f7;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #7a7875;
  background-color: #eeebe8;
}
.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #7a7875;
  border-color: #7a7875;
}

.list-group-item-dark {
  color: #6c6357;
  background-color: #f2ede7;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #6c6357;
  background-color: #e9e1d6;
}
.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #6c6357;
  border-color: #6c6357;
}

.list-group-item-bronze {
  color: #493b29;
  background-color: #dfd8cd;
}
.list-group-item-bronze.list-group-item-action:hover, .list-group-item-bronze.list-group-item-action:focus {
  color: #493b29;
  background-color: #d5ccbd;
}
.list-group-item-bronze.list-group-item-action.active {
  color: #ffffff;
  background-color: #493b29;
  border-color: #493b29;
}

.list-group-item-beige-dark {
  color: #6c6357;
  background-color: #f2ede7;
}
.list-group-item-beige-dark.list-group-item-action:hover, .list-group-item-beige-dark.list-group-item-action:focus {
  color: #6c6357;
  background-color: #e9e1d6;
}
.list-group-item-beige-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #6c6357;
  border-color: #6c6357;
}

.list-group-item-beige-light {
  color: #7a7875;
  background-color: #f9f8f7;
}
.list-group-item-beige-light.list-group-item-action:hover, .list-group-item-beige-light.list-group-item-action:focus {
  color: #7a7875;
  background-color: #eeebe8;
}
.list-group-item-beige-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #7a7875;
  border-color: #7a7875;
}

.list-group-item-mint {
  color: #656c59;
  background-color: #eef2e8;
}
.list-group-item-mint.list-group-item-action:hover, .list-group-item-mint.list-group-item-action:focus {
  color: #656c59;
  background-color: #e2e9d8;
}
.list-group-item-mint.list-group-item-action.active {
  color: #ffffff;
  background-color: #656c59;
  border-color: #656c59;
}

.list-group-item-red {
  color: #5f0101;
  background-color: #ebb8b8;
}
.list-group-item-red.list-group-item-action:hover, .list-group-item-red.list-group-item-action:focus {
  color: #5f0101;
  background-color: #e5a4a4;
}
.list-group-item-red.list-group-item-action.active {
  color: #ffffff;
  background-color: #5f0101;
  border-color: #5f0101;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 2px solid #000000;
  border-top-left-radius: calc(0.3rem - 2px);
  border-top-right-radius: calc(0.3rem - 2px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.75;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 2px solid #000000;
  border-bottom-right-radius: calc(0.3rem - 2px);
  border-bottom-left-radius: calc(0.3rem - 2px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 1025px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1399px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #8D724E !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #6c573c !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #b60202 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #840101 !important;
}

.bg-light {
  background-color: #EAE7E1 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d5cfc3 !important;
}

.bg-dark {
  background-color: #CFBFA8 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #bda787 !important;
}

.bg-bronze {
  background-color: #8D724E !important;
}

a.bg-bronze:hover, a.bg-bronze:focus,
button.bg-bronze:hover,
button.bg-bronze:focus {
  background-color: #6c573c !important;
}

.bg-beige-dark {
  background-color: #CFBFA8 !important;
}

a.bg-beige-dark:hover, a.bg-beige-dark:focus,
button.bg-beige-dark:hover,
button.bg-beige-dark:focus {
  background-color: #bda787 !important;
}

.bg-beige-light {
  background-color: #EAE7E1 !important;
}

a.bg-beige-light:hover, a.bg-beige-light:focus,
button.bg-beige-light:hover,
button.bg-beige-light:focus {
  background-color: #d5cfc3 !important;
}

.bg-mint {
  background-color: #C3CFAC !important;
}

a.bg-mint:hover, a.bg-mint:focus,
button.bg-mint:hover,
button.bg-mint:focus {
  background-color: #acbc8c !important;
}

.bg-red {
  background-color: #b60202 !important;
}

a.bg-red:hover, a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
  background-color: #840101 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 2px solid #000000 !important;
}

.border-top {
  border-top: 2px solid #000000 !important;
}

.border-right {
  border-right: 2px solid #000000 !important;
}

.border-bottom {
  border-bottom: 2px solid #000000 !important;
}

.border-left {
  border-left: 2px solid #000000 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #8D724E !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #b60202 !important;
}

.border-light {
  border-color: #EAE7E1 !important;
}

.border-dark {
  border-color: #CFBFA8 !important;
}

.border-bronze {
  border-color: #8D724E !important;
}

.border-beige-dark {
  border-color: #CFBFA8 !important;
}

.border-beige-light {
  border-color: #EAE7E1 !important;
}

.border-mint {
  border-color: #C3CFAC !important;
}

.border-red {
  border-color: #b60202 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0 !important;
}

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 325px) {
  .d-xs-none {
    display: none !important;
  }

  .d-xs-inline {
    display: inline !important;
  }

  .d-xs-inline-block {
    display: inline-block !important;
  }

  .d-xs-block {
    display: block !important;
  }

  .d-xs-table {
    display: table !important;
  }

  .d-xs-table-row {
    display: table-row !important;
  }

  .d-xs-table-cell {
    display: table-cell !important;
  }

  .d-xs-flex {
    display: flex !important;
  }

  .d-xs-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1025px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1399px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 325px) {
  .flex-xs-row {
    flex-direction: row !important;
  }

  .flex-xs-column {
    flex-direction: column !important;
  }

  .flex-xs-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xs-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xs-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xs-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xs-fill {
    flex: 1 1 auto !important;
  }

  .flex-xs-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xs-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xs-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xs-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xs-start {
    justify-content: flex-start !important;
  }

  .justify-content-xs-end {
    justify-content: flex-end !important;
  }

  .justify-content-xs-center {
    justify-content: center !important;
  }

  .justify-content-xs-between {
    justify-content: space-between !important;
  }

  .justify-content-xs-around {
    justify-content: space-around !important;
  }

  .align-items-xs-start {
    align-items: flex-start !important;
  }

  .align-items-xs-end {
    align-items: flex-end !important;
  }

  .align-items-xs-center {
    align-items: center !important;
  }

  .align-items-xs-baseline {
    align-items: baseline !important;
  }

  .align-items-xs-stretch {
    align-items: stretch !important;
  }

  .align-content-xs-start {
    align-content: flex-start !important;
  }

  .align-content-xs-end {
    align-content: flex-end !important;
  }

  .align-content-xs-center {
    align-content: center !important;
  }

  .align-content-xs-between {
    align-content: space-between !important;
  }

  .align-content-xs-around {
    align-content: space-around !important;
  }

  .align-content-xs-stretch {
    align-content: stretch !important;
  }

  .align-self-xs-auto {
    align-self: auto !important;
  }

  .align-self-xs-start {
    align-self: flex-start !important;
  }

  .align-self-xs-end {
    align-self: flex-end !important;
  }

  .align-self-xs-center {
    align-self: center !important;
  }

  .align-self-xs-baseline {
    align-self: baseline !important;
  }

  .align-self-xs-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1025px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1399px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 325px) {
  .float-xs-left {
    float: left !important;
  }

  .float-xs-right {
    float: right !important;
  }

  .float-xs-none {
    float: none !important;
  }
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 1025px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1399px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0, .newsletter-form form input.btn.btn-primary,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.625rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.625rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.625rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.625rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.625rem !important;
}

.m-2 {
  margin: 1.25rem !important;
}

.mt-2,
.my-2 {
  margin-top: 1.25rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 1.25rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 1.25rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 1.25rem !important;
}

.m-3 {
  margin: 1.875rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1.875rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1.875rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1.875rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1.875rem !important;
}

.m-4 {
  margin: 2.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 2.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 2.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 2.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 2.5rem !important;
}

.m-5 {
  margin: 3.125rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3.125rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3.125rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3.125rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3.125rem !important;
}

.m-6 {
  margin: 3.75rem !important;
}

.mt-6,
.my-6 {
  margin-top: 3.75rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 3.75rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 3.75rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 3.75rem !important;
}

.m-7 {
  margin: 4.375rem !important;
}

.mt-7,
.my-7 {
  margin-top: 4.375rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 4.375rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 4.375rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 4.375rem !important;
}

.m-8 {
  margin: 5rem !important;
}

.mt-8,
.my-8 {
  margin-top: 5rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 5rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 5rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.625rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.625rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.625rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.625rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.625rem !important;
}

.p-2 {
  padding: 1.25rem !important;
}

.pt-2,
.py-2 {
  padding-top: 1.25rem !important;
}

.pr-2,
.px-2 {
  padding-right: 1.25rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 1.25rem !important;
}

.pl-2,
.px-2 {
  padding-left: 1.25rem !important;
}

.p-3 {
  padding: 1.875rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1.875rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1.875rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1.875rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1.875rem !important;
}

.p-4 {
  padding: 2.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 2.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 2.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 2.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 2.5rem !important;
}

.p-5 {
  padding: 3.125rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3.125rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3.125rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3.125rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3.125rem !important;
}

.p-6 {
  padding: 3.75rem !important;
}

.pt-6,
.py-6 {
  padding-top: 3.75rem !important;
}

.pr-6,
.px-6 {
  padding-right: 3.75rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 3.75rem !important;
}

.pl-6,
.px-6 {
  padding-left: 3.75rem !important;
}

.p-7 {
  padding: 4.375rem !important;
}

.pt-7,
.py-7 {
  padding-top: 4.375rem !important;
}

.pr-7,
.px-7 {
  padding-right: 4.375rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 4.375rem !important;
}

.pl-7,
.px-7 {
  padding-left: 4.375rem !important;
}

.p-8 {
  padding: 5rem !important;
}

.pt-8,
.py-8 {
  padding-top: 5rem !important;
}

.pr-8,
.px-8 {
  padding-right: 5rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 5rem !important;
}

.pl-8,
.px-8 {
  padding-left: 5rem !important;
}

.m-n1 {
  margin: -0.625rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.625rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.625rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.625rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.625rem !important;
}

.m-n2 {
  margin: -1.25rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -1.25rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -1.25rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -1.25rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -1.25rem !important;
}

.m-n3 {
  margin: -1.875rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1.875rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1.875rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1.875rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1.875rem !important;
}

.m-n4 {
  margin: -2.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -2.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -2.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -2.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -2.5rem !important;
}

.m-n5 {
  margin: -3.125rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3.125rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3.125rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3.125rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3.125rem !important;
}

.m-n6 {
  margin: -3.75rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -3.75rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -3.75rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -3.75rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -3.75rem !important;
}

.m-n7 {
  margin: -4.375rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -4.375rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -4.375rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -4.375rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -4.375rem !important;
}

.m-n8 {
  margin: -5rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -5rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -5rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -5rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 325px) {
  .m-xs-0 {
    margin: 0 !important;
  }

  .mt-xs-0,
.my-xs-0 {
    margin-top: 0 !important;
  }

  .mr-xs-0,
.mx-xs-0 {
    margin-right: 0 !important;
  }

  .mb-xs-0,
.my-xs-0 {
    margin-bottom: 0 !important;
  }

  .ml-xs-0,
.mx-xs-0 {
    margin-left: 0 !important;
  }

  .m-xs-1 {
    margin: 0.625rem !important;
  }

  .mt-xs-1,
.my-xs-1 {
    margin-top: 0.625rem !important;
  }

  .mr-xs-1,
.mx-xs-1 {
    margin-right: 0.625rem !important;
  }

  .mb-xs-1,
.my-xs-1 {
    margin-bottom: 0.625rem !important;
  }

  .ml-xs-1,
.mx-xs-1 {
    margin-left: 0.625rem !important;
  }

  .m-xs-2 {
    margin: 1.25rem !important;
  }

  .mt-xs-2,
.my-xs-2 {
    margin-top: 1.25rem !important;
  }

  .mr-xs-2,
.mx-xs-2 {
    margin-right: 1.25rem !important;
  }

  .mb-xs-2,
.my-xs-2 {
    margin-bottom: 1.25rem !important;
  }

  .ml-xs-2,
.mx-xs-2 {
    margin-left: 1.25rem !important;
  }

  .m-xs-3 {
    margin: 1.875rem !important;
  }

  .mt-xs-3,
.my-xs-3 {
    margin-top: 1.875rem !important;
  }

  .mr-xs-3,
.mx-xs-3 {
    margin-right: 1.875rem !important;
  }

  .mb-xs-3,
.my-xs-3 {
    margin-bottom: 1.875rem !important;
  }

  .ml-xs-3,
.mx-xs-3 {
    margin-left: 1.875rem !important;
  }

  .m-xs-4 {
    margin: 2.5rem !important;
  }

  .mt-xs-4,
.my-xs-4 {
    margin-top: 2.5rem !important;
  }

  .mr-xs-4,
.mx-xs-4 {
    margin-right: 2.5rem !important;
  }

  .mb-xs-4,
.my-xs-4 {
    margin-bottom: 2.5rem !important;
  }

  .ml-xs-4,
.mx-xs-4 {
    margin-left: 2.5rem !important;
  }

  .m-xs-5 {
    margin: 3.125rem !important;
  }

  .mt-xs-5,
.my-xs-5 {
    margin-top: 3.125rem !important;
  }

  .mr-xs-5,
.mx-xs-5 {
    margin-right: 3.125rem !important;
  }

  .mb-xs-5,
.my-xs-5 {
    margin-bottom: 3.125rem !important;
  }

  .ml-xs-5,
.mx-xs-5 {
    margin-left: 3.125rem !important;
  }

  .m-xs-6 {
    margin: 3.75rem !important;
  }

  .mt-xs-6,
.my-xs-6 {
    margin-top: 3.75rem !important;
  }

  .mr-xs-6,
.mx-xs-6 {
    margin-right: 3.75rem !important;
  }

  .mb-xs-6,
.my-xs-6 {
    margin-bottom: 3.75rem !important;
  }

  .ml-xs-6,
.mx-xs-6 {
    margin-left: 3.75rem !important;
  }

  .m-xs-7 {
    margin: 4.375rem !important;
  }

  .mt-xs-7,
.my-xs-7 {
    margin-top: 4.375rem !important;
  }

  .mr-xs-7,
.mx-xs-7 {
    margin-right: 4.375rem !important;
  }

  .mb-xs-7,
.my-xs-7 {
    margin-bottom: 4.375rem !important;
  }

  .ml-xs-7,
.mx-xs-7 {
    margin-left: 4.375rem !important;
  }

  .m-xs-8 {
    margin: 5rem !important;
  }

  .mt-xs-8,
.my-xs-8 {
    margin-top: 5rem !important;
  }

  .mr-xs-8,
.mx-xs-8 {
    margin-right: 5rem !important;
  }

  .mb-xs-8,
.my-xs-8 {
    margin-bottom: 5rem !important;
  }

  .ml-xs-8,
.mx-xs-8 {
    margin-left: 5rem !important;
  }

  .p-xs-0 {
    padding: 0 !important;
  }

  .pt-xs-0,
.py-xs-0 {
    padding-top: 0 !important;
  }

  .pr-xs-0,
.px-xs-0 {
    padding-right: 0 !important;
  }

  .pb-xs-0,
.py-xs-0 {
    padding-bottom: 0 !important;
  }

  .pl-xs-0,
.px-xs-0 {
    padding-left: 0 !important;
  }

  .p-xs-1 {
    padding: 0.625rem !important;
  }

  .pt-xs-1,
.py-xs-1 {
    padding-top: 0.625rem !important;
  }

  .pr-xs-1,
.px-xs-1 {
    padding-right: 0.625rem !important;
  }

  .pb-xs-1,
.py-xs-1 {
    padding-bottom: 0.625rem !important;
  }

  .pl-xs-1,
.px-xs-1 {
    padding-left: 0.625rem !important;
  }

  .p-xs-2 {
    padding: 1.25rem !important;
  }

  .pt-xs-2,
.py-xs-2 {
    padding-top: 1.25rem !important;
  }

  .pr-xs-2,
.px-xs-2 {
    padding-right: 1.25rem !important;
  }

  .pb-xs-2,
.py-xs-2 {
    padding-bottom: 1.25rem !important;
  }

  .pl-xs-2,
.px-xs-2 {
    padding-left: 1.25rem !important;
  }

  .p-xs-3 {
    padding: 1.875rem !important;
  }

  .pt-xs-3,
.py-xs-3 {
    padding-top: 1.875rem !important;
  }

  .pr-xs-3,
.px-xs-3 {
    padding-right: 1.875rem !important;
  }

  .pb-xs-3,
.py-xs-3 {
    padding-bottom: 1.875rem !important;
  }

  .pl-xs-3,
.px-xs-3 {
    padding-left: 1.875rem !important;
  }

  .p-xs-4 {
    padding: 2.5rem !important;
  }

  .pt-xs-4,
.py-xs-4 {
    padding-top: 2.5rem !important;
  }

  .pr-xs-4,
.px-xs-4 {
    padding-right: 2.5rem !important;
  }

  .pb-xs-4,
.py-xs-4 {
    padding-bottom: 2.5rem !important;
  }

  .pl-xs-4,
.px-xs-4 {
    padding-left: 2.5rem !important;
  }

  .p-xs-5 {
    padding: 3.125rem !important;
  }

  .pt-xs-5,
.py-xs-5 {
    padding-top: 3.125rem !important;
  }

  .pr-xs-5,
.px-xs-5 {
    padding-right: 3.125rem !important;
  }

  .pb-xs-5,
.py-xs-5 {
    padding-bottom: 3.125rem !important;
  }

  .pl-xs-5,
.px-xs-5 {
    padding-left: 3.125rem !important;
  }

  .p-xs-6 {
    padding: 3.75rem !important;
  }

  .pt-xs-6,
.py-xs-6 {
    padding-top: 3.75rem !important;
  }

  .pr-xs-6,
.px-xs-6 {
    padding-right: 3.75rem !important;
  }

  .pb-xs-6,
.py-xs-6 {
    padding-bottom: 3.75rem !important;
  }

  .pl-xs-6,
.px-xs-6 {
    padding-left: 3.75rem !important;
  }

  .p-xs-7 {
    padding: 4.375rem !important;
  }

  .pt-xs-7,
.py-xs-7 {
    padding-top: 4.375rem !important;
  }

  .pr-xs-7,
.px-xs-7 {
    padding-right: 4.375rem !important;
  }

  .pb-xs-7,
.py-xs-7 {
    padding-bottom: 4.375rem !important;
  }

  .pl-xs-7,
.px-xs-7 {
    padding-left: 4.375rem !important;
  }

  .p-xs-8 {
    padding: 5rem !important;
  }

  .pt-xs-8,
.py-xs-8 {
    padding-top: 5rem !important;
  }

  .pr-xs-8,
.px-xs-8 {
    padding-right: 5rem !important;
  }

  .pb-xs-8,
.py-xs-8 {
    padding-bottom: 5rem !important;
  }

  .pl-xs-8,
.px-xs-8 {
    padding-left: 5rem !important;
  }

  .m-xs-n1 {
    margin: -0.625rem !important;
  }

  .mt-xs-n1,
.my-xs-n1 {
    margin-top: -0.625rem !important;
  }

  .mr-xs-n1,
.mx-xs-n1 {
    margin-right: -0.625rem !important;
  }

  .mb-xs-n1,
.my-xs-n1 {
    margin-bottom: -0.625rem !important;
  }

  .ml-xs-n1,
.mx-xs-n1 {
    margin-left: -0.625rem !important;
  }

  .m-xs-n2 {
    margin: -1.25rem !important;
  }

  .mt-xs-n2,
.my-xs-n2 {
    margin-top: -1.25rem !important;
  }

  .mr-xs-n2,
.mx-xs-n2 {
    margin-right: -1.25rem !important;
  }

  .mb-xs-n2,
.my-xs-n2 {
    margin-bottom: -1.25rem !important;
  }

  .ml-xs-n2,
.mx-xs-n2 {
    margin-left: -1.25rem !important;
  }

  .m-xs-n3 {
    margin: -1.875rem !important;
  }

  .mt-xs-n3,
.my-xs-n3 {
    margin-top: -1.875rem !important;
  }

  .mr-xs-n3,
.mx-xs-n3 {
    margin-right: -1.875rem !important;
  }

  .mb-xs-n3,
.my-xs-n3 {
    margin-bottom: -1.875rem !important;
  }

  .ml-xs-n3,
.mx-xs-n3 {
    margin-left: -1.875rem !important;
  }

  .m-xs-n4 {
    margin: -2.5rem !important;
  }

  .mt-xs-n4,
.my-xs-n4 {
    margin-top: -2.5rem !important;
  }

  .mr-xs-n4,
.mx-xs-n4 {
    margin-right: -2.5rem !important;
  }

  .mb-xs-n4,
.my-xs-n4 {
    margin-bottom: -2.5rem !important;
  }

  .ml-xs-n4,
.mx-xs-n4 {
    margin-left: -2.5rem !important;
  }

  .m-xs-n5 {
    margin: -3.125rem !important;
  }

  .mt-xs-n5,
.my-xs-n5 {
    margin-top: -3.125rem !important;
  }

  .mr-xs-n5,
.mx-xs-n5 {
    margin-right: -3.125rem !important;
  }

  .mb-xs-n5,
.my-xs-n5 {
    margin-bottom: -3.125rem !important;
  }

  .ml-xs-n5,
.mx-xs-n5 {
    margin-left: -3.125rem !important;
  }

  .m-xs-n6 {
    margin: -3.75rem !important;
  }

  .mt-xs-n6,
.my-xs-n6 {
    margin-top: -3.75rem !important;
  }

  .mr-xs-n6,
.mx-xs-n6 {
    margin-right: -3.75rem !important;
  }

  .mb-xs-n6,
.my-xs-n6 {
    margin-bottom: -3.75rem !important;
  }

  .ml-xs-n6,
.mx-xs-n6 {
    margin-left: -3.75rem !important;
  }

  .m-xs-n7 {
    margin: -4.375rem !important;
  }

  .mt-xs-n7,
.my-xs-n7 {
    margin-top: -4.375rem !important;
  }

  .mr-xs-n7,
.mx-xs-n7 {
    margin-right: -4.375rem !important;
  }

  .mb-xs-n7,
.my-xs-n7 {
    margin-bottom: -4.375rem !important;
  }

  .ml-xs-n7,
.mx-xs-n7 {
    margin-left: -4.375rem !important;
  }

  .m-xs-n8 {
    margin: -5rem !important;
  }

  .mt-xs-n8,
.my-xs-n8 {
    margin-top: -5rem !important;
  }

  .mr-xs-n8,
.mx-xs-n8 {
    margin-right: -5rem !important;
  }

  .mb-xs-n8,
.my-xs-n8 {
    margin-bottom: -5rem !important;
  }

  .ml-xs-n8,
.mx-xs-n8 {
    margin-left: -5rem !important;
  }

  .m-xs-auto {
    margin: auto !important;
  }

  .mt-xs-auto,
.my-xs-auto {
    margin-top: auto !important;
  }

  .mr-xs-auto,
.mx-xs-auto {
    margin-right: auto !important;
  }

  .mb-xs-auto,
.my-xs-auto {
    margin-bottom: auto !important;
  }

  .ml-xs-auto,
.mx-xs-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.625rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.625rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.625rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.625rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.625rem !important;
  }

  .m-sm-2 {
    margin: 1.25rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 1.25rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 1.25rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 1.25rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 1.25rem !important;
  }

  .m-sm-3 {
    margin: 1.875rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1.875rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1.875rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1.875rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1.875rem !important;
  }

  .m-sm-4 {
    margin: 2.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 2.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 2.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 2.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 2.5rem !important;
  }

  .m-sm-5 {
    margin: 3.125rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3.125rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3.125rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3.125rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3.125rem !important;
  }

  .m-sm-6 {
    margin: 3.75rem !important;
  }

  .mt-sm-6,
.my-sm-6 {
    margin-top: 3.75rem !important;
  }

  .mr-sm-6,
.mx-sm-6 {
    margin-right: 3.75rem !important;
  }

  .mb-sm-6,
.my-sm-6 {
    margin-bottom: 3.75rem !important;
  }

  .ml-sm-6,
.mx-sm-6 {
    margin-left: 3.75rem !important;
  }

  .m-sm-7 {
    margin: 4.375rem !important;
  }

  .mt-sm-7,
.my-sm-7 {
    margin-top: 4.375rem !important;
  }

  .mr-sm-7,
.mx-sm-7 {
    margin-right: 4.375rem !important;
  }

  .mb-sm-7,
.my-sm-7 {
    margin-bottom: 4.375rem !important;
  }

  .ml-sm-7,
.mx-sm-7 {
    margin-left: 4.375rem !important;
  }

  .m-sm-8 {
    margin: 5rem !important;
  }

  .mt-sm-8,
.my-sm-8 {
    margin-top: 5rem !important;
  }

  .mr-sm-8,
.mx-sm-8 {
    margin-right: 5rem !important;
  }

  .mb-sm-8,
.my-sm-8 {
    margin-bottom: 5rem !important;
  }

  .ml-sm-8,
.mx-sm-8 {
    margin-left: 5rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.625rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.625rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.625rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.625rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.625rem !important;
  }

  .p-sm-2 {
    padding: 1.25rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 1.25rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 1.25rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 1.25rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 1.25rem !important;
  }

  .p-sm-3 {
    padding: 1.875rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1.875rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1.875rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1.875rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1.875rem !important;
  }

  .p-sm-4 {
    padding: 2.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 2.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 2.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 2.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 2.5rem !important;
  }

  .p-sm-5 {
    padding: 3.125rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3.125rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3.125rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3.125rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3.125rem !important;
  }

  .p-sm-6 {
    padding: 3.75rem !important;
  }

  .pt-sm-6,
.py-sm-6 {
    padding-top: 3.75rem !important;
  }

  .pr-sm-6,
.px-sm-6 {
    padding-right: 3.75rem !important;
  }

  .pb-sm-6,
.py-sm-6 {
    padding-bottom: 3.75rem !important;
  }

  .pl-sm-6,
.px-sm-6 {
    padding-left: 3.75rem !important;
  }

  .p-sm-7 {
    padding: 4.375rem !important;
  }

  .pt-sm-7,
.py-sm-7 {
    padding-top: 4.375rem !important;
  }

  .pr-sm-7,
.px-sm-7 {
    padding-right: 4.375rem !important;
  }

  .pb-sm-7,
.py-sm-7 {
    padding-bottom: 4.375rem !important;
  }

  .pl-sm-7,
.px-sm-7 {
    padding-left: 4.375rem !important;
  }

  .p-sm-8 {
    padding: 5rem !important;
  }

  .pt-sm-8,
.py-sm-8 {
    padding-top: 5rem !important;
  }

  .pr-sm-8,
.px-sm-8 {
    padding-right: 5rem !important;
  }

  .pb-sm-8,
.py-sm-8 {
    padding-bottom: 5rem !important;
  }

  .pl-sm-8,
.px-sm-8 {
    padding-left: 5rem !important;
  }

  .m-sm-n1 {
    margin: -0.625rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.625rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.625rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.625rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.625rem !important;
  }

  .m-sm-n2 {
    margin: -1.25rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -1.25rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -1.25rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -1.25rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -1.25rem !important;
  }

  .m-sm-n3 {
    margin: -1.875rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1.875rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1.875rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1.875rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1.875rem !important;
  }

  .m-sm-n4 {
    margin: -2.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -2.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -2.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -2.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -2.5rem !important;
  }

  .m-sm-n5 {
    margin: -3.125rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3.125rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3.125rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3.125rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3.125rem !important;
  }

  .m-sm-n6 {
    margin: -3.75rem !important;
  }

  .mt-sm-n6,
.my-sm-n6 {
    margin-top: -3.75rem !important;
  }

  .mr-sm-n6,
.mx-sm-n6 {
    margin-right: -3.75rem !important;
  }

  .mb-sm-n6,
.my-sm-n6 {
    margin-bottom: -3.75rem !important;
  }

  .ml-sm-n6,
.mx-sm-n6 {
    margin-left: -3.75rem !important;
  }

  .m-sm-n7 {
    margin: -4.375rem !important;
  }

  .mt-sm-n7,
.my-sm-n7 {
    margin-top: -4.375rem !important;
  }

  .mr-sm-n7,
.mx-sm-n7 {
    margin-right: -4.375rem !important;
  }

  .mb-sm-n7,
.my-sm-n7 {
    margin-bottom: -4.375rem !important;
  }

  .ml-sm-n7,
.mx-sm-n7 {
    margin-left: -4.375rem !important;
  }

  .m-sm-n8 {
    margin: -5rem !important;
  }

  .mt-sm-n8,
.my-sm-n8 {
    margin-top: -5rem !important;
  }

  .mr-sm-n8,
.mx-sm-n8 {
    margin-right: -5rem !important;
  }

  .mb-sm-n8,
.my-sm-n8 {
    margin-bottom: -5rem !important;
  }

  .ml-sm-n8,
.mx-sm-n8 {
    margin-left: -5rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.625rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.625rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.625rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.625rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.625rem !important;
  }

  .m-md-2 {
    margin: 1.25rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 1.25rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 1.25rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 1.25rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 1.25rem !important;
  }

  .m-md-3 {
    margin: 1.875rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1.875rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1.875rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1.875rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1.875rem !important;
  }

  .m-md-4 {
    margin: 2.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 2.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 2.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 2.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 2.5rem !important;
  }

  .m-md-5 {
    margin: 3.125rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3.125rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3.125rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3.125rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3.125rem !important;
  }

  .m-md-6 {
    margin: 3.75rem !important;
  }

  .mt-md-6,
.my-md-6 {
    margin-top: 3.75rem !important;
  }

  .mr-md-6,
.mx-md-6 {
    margin-right: 3.75rem !important;
  }

  .mb-md-6,
.my-md-6 {
    margin-bottom: 3.75rem !important;
  }

  .ml-md-6,
.mx-md-6 {
    margin-left: 3.75rem !important;
  }

  .m-md-7 {
    margin: 4.375rem !important;
  }

  .mt-md-7,
.my-md-7 {
    margin-top: 4.375rem !important;
  }

  .mr-md-7,
.mx-md-7 {
    margin-right: 4.375rem !important;
  }

  .mb-md-7,
.my-md-7 {
    margin-bottom: 4.375rem !important;
  }

  .ml-md-7,
.mx-md-7 {
    margin-left: 4.375rem !important;
  }

  .m-md-8 {
    margin: 5rem !important;
  }

  .mt-md-8,
.my-md-8 {
    margin-top: 5rem !important;
  }

  .mr-md-8,
.mx-md-8 {
    margin-right: 5rem !important;
  }

  .mb-md-8,
.my-md-8 {
    margin-bottom: 5rem !important;
  }

  .ml-md-8,
.mx-md-8 {
    margin-left: 5rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.625rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.625rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.625rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.625rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.625rem !important;
  }

  .p-md-2 {
    padding: 1.25rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 1.25rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 1.25rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 1.25rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 1.25rem !important;
  }

  .p-md-3 {
    padding: 1.875rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1.875rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1.875rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1.875rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1.875rem !important;
  }

  .p-md-4 {
    padding: 2.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 2.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 2.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 2.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 2.5rem !important;
  }

  .p-md-5 {
    padding: 3.125rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3.125rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3.125rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3.125rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3.125rem !important;
  }

  .p-md-6 {
    padding: 3.75rem !important;
  }

  .pt-md-6,
.py-md-6 {
    padding-top: 3.75rem !important;
  }

  .pr-md-6,
.px-md-6 {
    padding-right: 3.75rem !important;
  }

  .pb-md-6,
.py-md-6 {
    padding-bottom: 3.75rem !important;
  }

  .pl-md-6,
.px-md-6 {
    padding-left: 3.75rem !important;
  }

  .p-md-7 {
    padding: 4.375rem !important;
  }

  .pt-md-7,
.py-md-7 {
    padding-top: 4.375rem !important;
  }

  .pr-md-7,
.px-md-7 {
    padding-right: 4.375rem !important;
  }

  .pb-md-7,
.py-md-7 {
    padding-bottom: 4.375rem !important;
  }

  .pl-md-7,
.px-md-7 {
    padding-left: 4.375rem !important;
  }

  .p-md-8 {
    padding: 5rem !important;
  }

  .pt-md-8,
.py-md-8 {
    padding-top: 5rem !important;
  }

  .pr-md-8,
.px-md-8 {
    padding-right: 5rem !important;
  }

  .pb-md-8,
.py-md-8 {
    padding-bottom: 5rem !important;
  }

  .pl-md-8,
.px-md-8 {
    padding-left: 5rem !important;
  }

  .m-md-n1 {
    margin: -0.625rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.625rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.625rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.625rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.625rem !important;
  }

  .m-md-n2 {
    margin: -1.25rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -1.25rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -1.25rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -1.25rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -1.25rem !important;
  }

  .m-md-n3 {
    margin: -1.875rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1.875rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1.875rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1.875rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1.875rem !important;
  }

  .m-md-n4 {
    margin: -2.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -2.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -2.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -2.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -2.5rem !important;
  }

  .m-md-n5 {
    margin: -3.125rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3.125rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3.125rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3.125rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3.125rem !important;
  }

  .m-md-n6 {
    margin: -3.75rem !important;
  }

  .mt-md-n6,
.my-md-n6 {
    margin-top: -3.75rem !important;
  }

  .mr-md-n6,
.mx-md-n6 {
    margin-right: -3.75rem !important;
  }

  .mb-md-n6,
.my-md-n6 {
    margin-bottom: -3.75rem !important;
  }

  .ml-md-n6,
.mx-md-n6 {
    margin-left: -3.75rem !important;
  }

  .m-md-n7 {
    margin: -4.375rem !important;
  }

  .mt-md-n7,
.my-md-n7 {
    margin-top: -4.375rem !important;
  }

  .mr-md-n7,
.mx-md-n7 {
    margin-right: -4.375rem !important;
  }

  .mb-md-n7,
.my-md-n7 {
    margin-bottom: -4.375rem !important;
  }

  .ml-md-n7,
.mx-md-n7 {
    margin-left: -4.375rem !important;
  }

  .m-md-n8 {
    margin: -5rem !important;
  }

  .mt-md-n8,
.my-md-n8 {
    margin-top: -5rem !important;
  }

  .mr-md-n8,
.mx-md-n8 {
    margin-right: -5rem !important;
  }

  .mb-md-n8,
.my-md-n8 {
    margin-bottom: -5rem !important;
  }

  .ml-md-n8,
.mx-md-n8 {
    margin-left: -5rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1025px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.625rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.625rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.625rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.625rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.625rem !important;
  }

  .m-lg-2 {
    margin: 1.25rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 1.25rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 1.25rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 1.25rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 1.25rem !important;
  }

  .m-lg-3 {
    margin: 1.875rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1.875rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1.875rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1.875rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1.875rem !important;
  }

  .m-lg-4 {
    margin: 2.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 2.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 2.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 2.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 2.5rem !important;
  }

  .m-lg-5 {
    margin: 3.125rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3.125rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3.125rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3.125rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3.125rem !important;
  }

  .m-lg-6 {
    margin: 3.75rem !important;
  }

  .mt-lg-6,
.my-lg-6 {
    margin-top: 3.75rem !important;
  }

  .mr-lg-6,
.mx-lg-6 {
    margin-right: 3.75rem !important;
  }

  .mb-lg-6,
.my-lg-6 {
    margin-bottom: 3.75rem !important;
  }

  .ml-lg-6,
.mx-lg-6 {
    margin-left: 3.75rem !important;
  }

  .m-lg-7 {
    margin: 4.375rem !important;
  }

  .mt-lg-7,
.my-lg-7 {
    margin-top: 4.375rem !important;
  }

  .mr-lg-7,
.mx-lg-7 {
    margin-right: 4.375rem !important;
  }

  .mb-lg-7,
.my-lg-7 {
    margin-bottom: 4.375rem !important;
  }

  .ml-lg-7,
.mx-lg-7 {
    margin-left: 4.375rem !important;
  }

  .m-lg-8 {
    margin: 5rem !important;
  }

  .mt-lg-8,
.my-lg-8 {
    margin-top: 5rem !important;
  }

  .mr-lg-8,
.mx-lg-8 {
    margin-right: 5rem !important;
  }

  .mb-lg-8,
.my-lg-8 {
    margin-bottom: 5rem !important;
  }

  .ml-lg-8,
.mx-lg-8 {
    margin-left: 5rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.625rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.625rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.625rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.625rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.625rem !important;
  }

  .p-lg-2 {
    padding: 1.25rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 1.25rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 1.25rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 1.25rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 1.25rem !important;
  }

  .p-lg-3 {
    padding: 1.875rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1.875rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1.875rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1.875rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1.875rem !important;
  }

  .p-lg-4 {
    padding: 2.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 2.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 2.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 2.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 2.5rem !important;
  }

  .p-lg-5 {
    padding: 3.125rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3.125rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3.125rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3.125rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3.125rem !important;
  }

  .p-lg-6 {
    padding: 3.75rem !important;
  }

  .pt-lg-6,
.py-lg-6 {
    padding-top: 3.75rem !important;
  }

  .pr-lg-6,
.px-lg-6 {
    padding-right: 3.75rem !important;
  }

  .pb-lg-6,
.py-lg-6 {
    padding-bottom: 3.75rem !important;
  }

  .pl-lg-6,
.px-lg-6 {
    padding-left: 3.75rem !important;
  }

  .p-lg-7 {
    padding: 4.375rem !important;
  }

  .pt-lg-7,
.py-lg-7 {
    padding-top: 4.375rem !important;
  }

  .pr-lg-7,
.px-lg-7 {
    padding-right: 4.375rem !important;
  }

  .pb-lg-7,
.py-lg-7 {
    padding-bottom: 4.375rem !important;
  }

  .pl-lg-7,
.px-lg-7 {
    padding-left: 4.375rem !important;
  }

  .p-lg-8 {
    padding: 5rem !important;
  }

  .pt-lg-8,
.py-lg-8 {
    padding-top: 5rem !important;
  }

  .pr-lg-8,
.px-lg-8 {
    padding-right: 5rem !important;
  }

  .pb-lg-8,
.py-lg-8 {
    padding-bottom: 5rem !important;
  }

  .pl-lg-8,
.px-lg-8 {
    padding-left: 5rem !important;
  }

  .m-lg-n1 {
    margin: -0.625rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.625rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.625rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.625rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.625rem !important;
  }

  .m-lg-n2 {
    margin: -1.25rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -1.25rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -1.25rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -1.25rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -1.25rem !important;
  }

  .m-lg-n3 {
    margin: -1.875rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1.875rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1.875rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1.875rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1.875rem !important;
  }

  .m-lg-n4 {
    margin: -2.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -2.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -2.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -2.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -2.5rem !important;
  }

  .m-lg-n5 {
    margin: -3.125rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3.125rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3.125rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3.125rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3.125rem !important;
  }

  .m-lg-n6 {
    margin: -3.75rem !important;
  }

  .mt-lg-n6,
.my-lg-n6 {
    margin-top: -3.75rem !important;
  }

  .mr-lg-n6,
.mx-lg-n6 {
    margin-right: -3.75rem !important;
  }

  .mb-lg-n6,
.my-lg-n6 {
    margin-bottom: -3.75rem !important;
  }

  .ml-lg-n6,
.mx-lg-n6 {
    margin-left: -3.75rem !important;
  }

  .m-lg-n7 {
    margin: -4.375rem !important;
  }

  .mt-lg-n7,
.my-lg-n7 {
    margin-top: -4.375rem !important;
  }

  .mr-lg-n7,
.mx-lg-n7 {
    margin-right: -4.375rem !important;
  }

  .mb-lg-n7,
.my-lg-n7 {
    margin-bottom: -4.375rem !important;
  }

  .ml-lg-n7,
.mx-lg-n7 {
    margin-left: -4.375rem !important;
  }

  .m-lg-n8 {
    margin: -5rem !important;
  }

  .mt-lg-n8,
.my-lg-n8 {
    margin-top: -5rem !important;
  }

  .mr-lg-n8,
.mx-lg-n8 {
    margin-right: -5rem !important;
  }

  .mb-lg-n8,
.my-lg-n8 {
    margin-bottom: -5rem !important;
  }

  .ml-lg-n8,
.mx-lg-n8 {
    margin-left: -5rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1399px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.625rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.625rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.625rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.625rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.625rem !important;
  }

  .m-xl-2 {
    margin: 1.25rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 1.25rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 1.25rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 1.25rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 1.25rem !important;
  }

  .m-xl-3 {
    margin: 1.875rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1.875rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1.875rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1.875rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1.875rem !important;
  }

  .m-xl-4 {
    margin: 2.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 2.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 2.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 2.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 2.5rem !important;
  }

  .m-xl-5 {
    margin: 3.125rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3.125rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3.125rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3.125rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3.125rem !important;
  }

  .m-xl-6 {
    margin: 3.75rem !important;
  }

  .mt-xl-6,
.my-xl-6 {
    margin-top: 3.75rem !important;
  }

  .mr-xl-6,
.mx-xl-6 {
    margin-right: 3.75rem !important;
  }

  .mb-xl-6,
.my-xl-6 {
    margin-bottom: 3.75rem !important;
  }

  .ml-xl-6,
.mx-xl-6 {
    margin-left: 3.75rem !important;
  }

  .m-xl-7 {
    margin: 4.375rem !important;
  }

  .mt-xl-7,
.my-xl-7 {
    margin-top: 4.375rem !important;
  }

  .mr-xl-7,
.mx-xl-7 {
    margin-right: 4.375rem !important;
  }

  .mb-xl-7,
.my-xl-7 {
    margin-bottom: 4.375rem !important;
  }

  .ml-xl-7,
.mx-xl-7 {
    margin-left: 4.375rem !important;
  }

  .m-xl-8 {
    margin: 5rem !important;
  }

  .mt-xl-8,
.my-xl-8 {
    margin-top: 5rem !important;
  }

  .mr-xl-8,
.mx-xl-8 {
    margin-right: 5rem !important;
  }

  .mb-xl-8,
.my-xl-8 {
    margin-bottom: 5rem !important;
  }

  .ml-xl-8,
.mx-xl-8 {
    margin-left: 5rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.625rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.625rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.625rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.625rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.625rem !important;
  }

  .p-xl-2 {
    padding: 1.25rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 1.25rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 1.25rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 1.25rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 1.25rem !important;
  }

  .p-xl-3 {
    padding: 1.875rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1.875rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1.875rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1.875rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1.875rem !important;
  }

  .p-xl-4 {
    padding: 2.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 2.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 2.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 2.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 2.5rem !important;
  }

  .p-xl-5 {
    padding: 3.125rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3.125rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3.125rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3.125rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3.125rem !important;
  }

  .p-xl-6 {
    padding: 3.75rem !important;
  }

  .pt-xl-6,
.py-xl-6 {
    padding-top: 3.75rem !important;
  }

  .pr-xl-6,
.px-xl-6 {
    padding-right: 3.75rem !important;
  }

  .pb-xl-6,
.py-xl-6 {
    padding-bottom: 3.75rem !important;
  }

  .pl-xl-6,
.px-xl-6 {
    padding-left: 3.75rem !important;
  }

  .p-xl-7 {
    padding: 4.375rem !important;
  }

  .pt-xl-7,
.py-xl-7 {
    padding-top: 4.375rem !important;
  }

  .pr-xl-7,
.px-xl-7 {
    padding-right: 4.375rem !important;
  }

  .pb-xl-7,
.py-xl-7 {
    padding-bottom: 4.375rem !important;
  }

  .pl-xl-7,
.px-xl-7 {
    padding-left: 4.375rem !important;
  }

  .p-xl-8 {
    padding: 5rem !important;
  }

  .pt-xl-8,
.py-xl-8 {
    padding-top: 5rem !important;
  }

  .pr-xl-8,
.px-xl-8 {
    padding-right: 5rem !important;
  }

  .pb-xl-8,
.py-xl-8 {
    padding-bottom: 5rem !important;
  }

  .pl-xl-8,
.px-xl-8 {
    padding-left: 5rem !important;
  }

  .m-xl-n1 {
    margin: -0.625rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.625rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.625rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.625rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.625rem !important;
  }

  .m-xl-n2 {
    margin: -1.25rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -1.25rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -1.25rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -1.25rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -1.25rem !important;
  }

  .m-xl-n3 {
    margin: -1.875rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1.875rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1.875rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1.875rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1.875rem !important;
  }

  .m-xl-n4 {
    margin: -2.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -2.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -2.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -2.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -2.5rem !important;
  }

  .m-xl-n5 {
    margin: -3.125rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3.125rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3.125rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3.125rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3.125rem !important;
  }

  .m-xl-n6 {
    margin: -3.75rem !important;
  }

  .mt-xl-n6,
.my-xl-n6 {
    margin-top: -3.75rem !important;
  }

  .mr-xl-n6,
.mx-xl-n6 {
    margin-right: -3.75rem !important;
  }

  .mb-xl-n6,
.my-xl-n6 {
    margin-bottom: -3.75rem !important;
  }

  .ml-xl-n6,
.mx-xl-n6 {
    margin-left: -3.75rem !important;
  }

  .m-xl-n7 {
    margin: -4.375rem !important;
  }

  .mt-xl-n7,
.my-xl-n7 {
    margin-top: -4.375rem !important;
  }

  .mr-xl-n7,
.mx-xl-n7 {
    margin-right: -4.375rem !important;
  }

  .mb-xl-n7,
.my-xl-n7 {
    margin-bottom: -4.375rem !important;
  }

  .ml-xl-n7,
.mx-xl-n7 {
    margin-left: -4.375rem !important;
  }

  .m-xl-n8 {
    margin: -5rem !important;
  }

  .mt-xl-n8,
.my-xl-n8 {
    margin-top: -5rem !important;
  }

  .mr-xl-n8,
.mx-xl-n8 {
    margin-right: -5rem !important;
  }

  .mb-xl-n8,
.my-xl-n8 {
    margin-bottom: -5rem !important;
  }

  .ml-xl-n8,
.mx-xl-n8 {
    margin-left: -5rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 325px) {
  .text-xs-left {
    text-align: left !important;
  }

  .text-xs-right {
    text-align: right !important;
  }

  .text-xs-center {
    text-align: center !important;
  }
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 1025px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1399px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #8D724E !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #5c4a33 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #b60202 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #6a0101 !important;
}

.text-light {
  color: #EAE7E1 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbc3b4 !important;
}

.text-dark {
  color: #CFBFA8 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #b49b77 !important;
}

.text-bronze {
  color: #8D724E !important;
}

a.text-bronze:hover, a.text-bronze:focus {
  color: #5c4a33 !important;
}

.text-beige-dark {
  color: #CFBFA8 !important;
}

a.text-beige-dark:hover, a.text-beige-dark:focus {
  color: #b49b77 !important;
}

.text-beige-light {
  color: #EAE7E1 !important;
}

a.text-beige-light:hover, a.text-beige-light:focus {
  color: #cbc3b4 !important;
}

.text-mint {
  color: #C3CFAC !important;
}

a.text-mint:hover, a.text-mint:focus {
  color: #a0b37c !important;
}

.text-red {
  color: #b60202 !important;
}

a.text-red:hover, a.text-red:focus {
  color: #6a0101 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 2px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
.accordion-header .btn-link .accordion-header-text,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2, .accordion-header .btn-link .accordion-header-text,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 1025px !important;
  }

  .container {
    min-width: 1025px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 2px solid #000000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #ffffff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #000000;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #000000;
  }
}
h1, .h1 {
  display: block;
  font-family: "GT America", sans-serif;
  font-weight: 400;
  font-size: 1.875rem;
  line-height: 1.3;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding-bottom: 1.875rem;
}
@media (min-width: 1025px) {
  h1, .h1 {
    font-size: 3.125rem;
    padding-bottom: 3.125rem;
  }
}

.second-h1,
.hero-h1 {
  display: block;
  font-family: "Kokoro", serif;
  font-weight: 400;
  font-size: 2.187rem;
  line-height: 1.3;
  letter-spacing: 0.5px;
  text-transform: initial;
}
@media (min-width: 768px) {
  .second-h1,
.hero-h1 {
    font-size: 3.125rem;
  }
}
@media (min-width: 1025px) {
  .second-h1,
.hero-h1 {
    font-size: 5rem;
    margin-bottom: 0;
    line-height: 1;
  }
}

h2, .accordion-header .btn-link .accordion-header-text, .h2, .table-bordered thead th {
  display: block;
  font-family: "GT America", sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.33;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding-bottom: 1.875rem;
}
@media (min-width: 1025px) {
  h2, .accordion-header .btn-link .accordion-header-text, .h2, .table-bordered thead th {
    font-size: 1.875rem;
    padding-bottom: 1.875rem;
  }
}

.second-h2 {
  display: block;
  font-family: "Kokoro", serif;
  font-weight: 400;
  font-size: 2.187rem;
  line-height: 1.19;
  letter-spacing: 0.5px;
  text-transform: initial;
  padding-bottom: 1.875rem;
}
@media (min-width: 1025px) {
  .second-h2 {
    font-size: 3.437rem;
  }
}

h3, .h3 {
  display: block;
  font-family: "Kokoro", serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.75;
  letter-spacing: 0.5px;
  margin-bottom: 0;
}
@media (min-width: 1025px) {
  h3, .h3 {
    font-size: 1.25rem;
    margin-bottom: 0;
  }
}

.second-h3 {
  display: block;
  font-family: "GT America", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 4px;
  text-transform: uppercase;
  padding-bottom: 1.875rem;
  color: #8D724E;
}

h4, .h4 {
  display: block;
  font-size: 0.75rem;
  margin-bottom: 0;
  line-height: 1.166667;
}
@media (min-width: 1025px) {
  h4, .h4 {
    font-size: 0.875rem;
    line-height: 1.153846;
  }
}

.second-h4 {
  display: block;
  font-family: "GT America", sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.166667;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin-bottom: 0;
  color: #8D724E;
}

h5, .h5 {
  display: block;
  font-size: 0.75rem;
  margin-bottom: 0;
  line-height: 1.18181818;
}

h6, .h6 {
  display: block;
  font-size: 0.75rem;
  margin-bottom: 0;
  line-height: 1.5;
}
@media (min-width: 1025px) {
  h6, .h6 {
    line-height: 1.75;
  }
}

p {
  letter-spacing: 0.5px;
  font-size: 1rem;
}
@media (min-width: 1025px) {
  p {
    font-size: 1.25rem;
  }
}

ul li,
ol li {
  letter-spacing: 0.5px;
  font-size: 1rem;
}
@media (min-width: 1025px) {
  ul li,
ol li {
    font-size: 1.25rem;
  }
}

.smaller, .desc,
.desc p,
.img-desc,
.img-desc p,
.small,
.big {
  font-family: "Kokoro", serif;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.smaller, .desc,
.desc p,
.img-desc,
.img-desc p {
  font-size: 0.875rem;
  line-height: 20px;
}

small,
.small {
  font-size: 1rem;
  line-height: 1.5625;
}

.big {
  font-size: 1.25rem;
  line-height: 1.6;
}
@media (min-width: 1025px) {
  .big {
    font-size: 1.562rem;
  }
}

ul,
ol {
  padding-left: 1.125rem;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }

  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  /* ideally, transition speed should match zoom duration */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

.table-bordered {
  border: none;
}
.table-bordered thead th {
  padding: 50px 0;
  border: none;
}
.table-bordered tr td:first-of-type {
  border-left: none;
  padding-right: 5px;
}
.table-bordered tr td:last-of-type {
  border-right: none;
  padding-left: 5px;
}
.table-bordered td {
  padding: 20px 0;
}
.table-bordered td p {
  margin-bottom: 0;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.media-list {
  padding-left: 0;
  list-style: none;
}
.media-list .media + .media {
  margin-top: 0.5rem;
}

.media-left {
  padding-right: 0.5rem;
}
.media-heading {
  display: flex;
  align-items: center;
}
.media-heading > a {
  display: inline-flex;
  align-items: center;
}

.uploads-fileicon {
  margin-right: 0.25rem;
}
.uploads-fileicon svg {
  width: 1em;
  height: 1em;
}
.uploads-filesize {
  margin-left: 0.25rem;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 2px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 2px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: 0 0 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 2px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 0 0;
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 0;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-deck .card {
  margin-bottom: 20px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -20px;
    margin-left: -20px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 20px;
    margin-bottom: 0;
    margin-left: 20px;
  }
}

.card-group > .card {
  margin-bottom: 20px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -2px;
}

.card-img-top {
  overflow: hidden;
}
.card-img-top picture,
.card-img-top img {
  width: 100%;
  height: auto;
}

.card-body > *:first-child {
  margin-top: 0;
}
.card-body > *:last-child {
  margin-bottom: 0;
}

.accordion > .card > .card-header {
  margin-bottom: 0;
}

.accordion .card-body {
  padding: 0;
}

.accordion > .card:last-of-type {
  border-bottom: 2px solid #000000;
}

.accordion-item {
  background-color: transparent;
  border: none;
}

/*.accordion-item + .accordion-item {
    margin-top: 0.25rem;
}*/
.accordion-header {
  padding: 0;
  background-color: transparent;
  border: none;
  text-align: center;
}
.accordion-header .btn-link {
  position: relative;
  width: 100%;
  border: none;
  border-top: 2px solid #8D724E;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  background-color: transparent;
  color: #8D724E;
  margin-top: 0 !important;
}
@media (max-width: 1024.98px) {
  .accordion-header .btn-link {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.accordion-header .btn-link:hover, .accordion-header .btn-link:focus {
  text-decoration: none;
}
.accordion-header .btn-link .accordion-header-text {
  color: #8D724E;
  margin-bottom: 0;
}
@media (max-width: 1024.98px) {
  .accordion-header .btn-link .accordion-header-text {
    font-size: 0.875rem;
  }
}
.accordion-header .accordion-header-icon {
  position: absolute;
  height: 24px;
  width: 24px;
  top: 50%;
  right: 1.25rem;
  transform: translate(0, -50%);
}
@media (max-width: 1024.98px) {
  .accordion-header .accordion-header-icon {
    right: 0;
    width: 12px;
    height: 12px;
  }
}
.accordion-header .accordion-header-icon:before, .accordion-header .accordion-header-icon:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  height: 2px;
  width: 100%;
  background-color: #8D724E;
  transition: all 0.2s ease-in-out;
}
.accordion-header .accordion-header-icon:after {
  transform: rotate(0);
  background-color: #8D724E;
}
.accordion-header .btn-link.collapsed {
  border-top: 2px solid #000000;
}
.accordion-header .btn-link.collapsed .accordion-header-icon:after, .accordion-header .btn-link.collapsed .accordion-header-icon:before {
  background-color: #000000;
}
.accordion-header .btn-link.collapsed .accordion-header-icon:after {
  transform: rotate(90deg);
}
.accordion-header .btn-link.collapsed .accordion-header-text {
  color: #000000;
}

.accordion-content {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.625rem;
  margin-right: -0.625rem;
  padding-top: 15px;
  padding-bottom: 50px;
}

.accordion-content-item {
  flex-grow: 1;
  order: 1;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  width: 100%;
}

.accordion-content-text *:last-child {
  margin-bottom: 0;
}

/*.accordion-body {
    border-top: $card-border-width solid $card-border-color;
}*/
@media (min-width: 768px) {
  .accordion-content-right .accordion-content-item,
.accordion-content-left .accordion-content-item {
    width: 50%;
  }
  .accordion-content-right .accordion-content-item *:last-child,
.accordion-content-left .accordion-content-item *:last-child {
    margin-bottom: 0;
  }
}

.accordion-content-top .accordion-content-gallery,
.accordion-content-left .accordion-content-gallery {
  margin-bottom: 1.25rem;
}

@media (min-width: 768px) {
  .accordion-content-left .accordion-content-gallery {
    margin-bottom: 0;
  }
}

.accordion-content-bottom .accordion-content-gallery,
.accordion-content-right .accordion-content-gallery {
  margin-top: 1.25rem;
}

@media (min-width: 768px) {
  .accordion-content-right .accordion-content-media {
    margin-top: 0;
  }
}

.card-group-element {
  margin: -20px;
  margin-bottom: -20px !important;
  display: flex;
  flex-wrap: wrap;
}

.card-group-element-item {
  width: 100%;
  padding: 20px;
}
.card-group-element-item .card {
  height: 100%;
}
.card-group-element-item .card-title {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
}
.card-group-element-item .card-subtitle {
  font-size: 0.8rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
}
.card-group-element-item .card-title + .card-subtitle {
  margin-top: -0.75rem;
}
.card-group-element-item .card-footer {
  border-top: 0;
  padding: 1.25rem;
  background-color: transparent;
}

@media (min-width: 576px) {
  .card-group-element-columns-2 .card-group-element-item {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .card-group-element-columns-3 .card-group-element-item {
    width: 33.3333333333%;
  }
}

@media (min-width: 576px) {
  .card-group-element-columns-4 .card-group-element-item {
    width: 50%;
  }
}
@media (min-width: 1399px) {
  .card-group-element-columns-4 .card-group-element-item {
    width: 25%;
  }
}

/*** numbered accordion ******/
.numbered-accordion {
  counter-reset: section;
}
.numbered-accordion .accordion-header .btn-link.collapsed::before {
  color: #000000;
}
.numbered-accordion .accordion-header .btn-link::before {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  left: 1.25rem;
  font-size: 14px;
  counter-increment: section;
  content: counter(section, decimal-leading-zero) ".";
}
@media (max-width: 1024.98px) {
  .numbered-accordion .accordion-header .btn-link::before {
    left: 0;
    align-items: flex-start;
    padding-top: 20px;
    font-size: 12px;
  }
}
.numbered-accordion .accordion-header .accordion-header-text {
  text-align: left;
  font-size: 1.25rem;
}
@media (max-width: 1024.98px) {
  .numbered-accordion .accordion-header .accordion-header-text {
    font-size: 0.875rem;
  }
}

.abo-header-container {
  width: 100%;
  height: 100%;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.abo-header-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.abo-header-content .abo-header-title {
  border-top: 2px solid #000000;
  border-bottom: 2px solid #000000;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  width: 100%;
}
.abo-header-content .abo-header-title h3 {
  color: #000000;
  text-align: center;
  padding-bottom: 0;
}
.abo-header-content .abo-header-text {
  text-align: center;
}
.abo-header-content .abo-header-price {
  font-size: 1rem;
  font-family: "GT America", sans-serif;
  font-weight: 500;
  color: #8D724E;
  letter-spacing: 2.67px;
  line-height: 1.25;
  text-align: center;
}

.handpicked-container {
  max-width: 80%;
}

@media screen and (min-width: 576px) and (max-width: 1360px) {
  .abo-header-content .abo-header-price {
    text-align: left;
  }
  .abo-header-content .abo-header-price span {
    display: block;
    width: 100%;
  }
}
@media screen and (max-width: 1280px) {
  .abo-header-content .abo-header-price {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 1180px) {
  .abo-header-container .second-h1 {
    font-size: 3.125rem;
  }
  .abo-header-container .big {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 1025px) {
  .abo-header-container {
    max-width: 90%;
  }

  .handpicked-container {
    max-width: 100%;
  }

  .abo-header-logo-wrapper svg {
    width: 140px;
    height: 127px;
  }
}
@media screen and (max-width: 768px) {
  .abo-header-container .second-h1 {
    font-size: 2.187rem;
  }
  .abo-header-container .big {
    font-size: 1rem;
  }
  .abo-header-container p.small {
    font-size: 0.75rem;
  }
}
@media screen and (max-width: 576px) {
  .abo-header-container .second-h1 {
    padding-bottom: 0.625rem;
  }
  .abo-header-container .btn.btn-primary {
    margin-top: 0 !important;
  }
}
@media screen and (max-width: 325px) {
  .abo-header-img {
    padding-bottom: 140px;
    background: #f9f8f4;
  }
}
.frame-type-wvier_abo_header .scrolldown {
  cursor: pointer;
  position: absolute;
  z-index: 1010;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1024px) {
  .frame-type-wvier_abo_header .scrolldown {
    display: none;
  }
}
@media screen and (min-width: 576px) {
  .abo-header {
    max-height: calc(100vh - 120px);
    overflow: hidden;
  }
}
.downloads .download {
  display: flex;
  align-items: center;
}
.downloads .download img {
  width: 100px;
  height: 100px;
}
@media (max-width: 1398.98px) {
  .downloads .download img {
    width: 85px;
    height: 85px;
  }
}
@media (max-width: 767.98px) {
  .downloads .download img {
    width: 70px;
    height: 70px;
  }
}
.downloads .download a {
  font-size: 1.25rem;
  text-decoration: none;
}
@media (max-width: 1398.98px) {
  .downloads .download a {
    font-size: 1rem;
  }
}
@media (max-width: 767.98px) {
  .downloads .download a {
    font-size: 0.875rem;
  }
}
.downloads .download a .download-title {
  font-family: "GT America", sans-serif;
  text-transform: uppercase;
}
.downloads .download a span {
  display: block;
}
.downloads .download a:hover .download-title {
  color: #8D724E;
}
.downloads .download a:hover .download-file {
  text-decoration: underline;
}
@media (max-width: 1024.98px) {
  .downloads .download a .download-file {
    text-decoration: underline;
  }
}

.slick-arrow {
  width: 90px;
  height: 90px;
  border-radius: 100%;
  z-index: 100;
  transition: all 250ms ease-in-out;
}

.slick-arrow:hover {
  background: rgba(0, 0, 0, 0.5);
}

.slick-prev:before, .slick-next:before {
  opacity: 1;
  font-size: 40px;
}

/* NEWS */
.news.news-single {
  /*  .category-wrapper {
        margin-bottom: 20px;

        @include media-breakpoint-up(lg) {
          margin-bottom: 30px;
        }
      }
  */
}
.news.news-single .header h1 {
  padding-bottom: 0;
}
.news.news-single .teaser-text p {
  font-size: 1.25rem;
  line-height: 1.6;
  margin-bottom: 0;
}
@media (min-width: 1025px) {
  .news.news-single .teaser-text p {
    font-size: 1.562rem;
  }
}
@media (min-width: 1025px) {
  .news.news-single .tab-content .bodytext:not(:first-of-type) {
    border-left: 1px solid black;
  }
}

.btn.infoteaser-btn {
  position: fixed;
  left: 10px;
  bottom: 10px;
  display: block;
  width: 65px;
  height: 65px;
  padding: 6px;
  background: #EAE7E1;
  border: 0;
  border-radius: 50%;
  color: #212529;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.15);
  z-index: 900;
  appearance: none;
  pointer-events: auto;
}
.btn.infoteaser-btn:hover {
  background-color: #CFBFA8;
}
.btn.infoteaser-btn:not(:disabled):not(.disabled).active, .btn.infoteaser-btn:not(:disabled):not(.disabled):active {
  background-color: #EAE7E1;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.15);
}
.btn.infoteaser-btn:not(:disabled):not(.disabled).active:hover, .btn.infoteaser-btn:not(:disabled):not(.disabled):active:hover {
  background-color: #CFBFA8;
}
.btn.infoteaser-btn:active, .btn.infoteaser-btn.active {
  background-color: #EAE7E1;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.15);
}
.btn.infoteaser-btn:active:hover, .btn.infoteaser-btn.active:hover {
  background-color: #CFBFA8;
}
.btn.infoteaser-btn:focus, .btn.infoteaser-btn.focus {
  outline-color: #EAE7E1;
}
.btn.infoteaser-btn .svg-icon {
  width: 100%;
  height: 100%;
}
@media (min-width: 1025px) {
  .btn.infoteaser-btn {
    width: 80px;
    height: 80px;
    padding: 8px;
    bottom: 10px;
  }
}

.infoteaser-items-wrapper {
  position: fixed;
  display: flex;
  align-items: flex-end;
  max-width: 350px;
  top: 10px;
  right: 10px;
  left: 10px;
  bottom: 90px;
  z-index: 10000;
  pointer-events: none;
}
.infoteaser-items-wrapper.show-it .infoteaser-inner {
  animation-name: infoIn;
  animation-duration: 250ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}
.infoteaser-items-wrapper.hide-it .infoteaser-inner {
  animation-name: infoOut;
  animation-duration: 250ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}
.infoteaser-items-wrapper .infoteaser-inner {
  position: relative;
  padding: 3.25rem 1.25rem 3rem;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.15);
  background-color: #CFBFA8;
  pointer-events: auto;
}
.infoteaser-items-wrapper .infoteaser-inner .infoteaser-item {
  text-align: center;
}
.infoteaser-items-wrapper .infoteaser-inner .infoteaser-item .infoteaser-title {
  font-family: "Kokoro", serif;
  text-transform: none;
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 0;
}
@media (min-width: 1025px) {
  .infoteaser-items-wrapper .infoteaser-inner .infoteaser-item .infoteaser-title {
    font-size: 1.562rem;
  }
}
@media (min-width: 576px) {
  .infoteaser-items-wrapper {
    right: auto;
    max-width: 490px;
  }
}
@media (min-width: 1025px) {
  .infoteaser-items-wrapper {
    left: 25px;
    bottom: 105px;
  }
  .infoteaser-items-wrapper .infoteaser-inner {
    padding: 3.25rem 2.25rem 2.25rem;
  }
}

.infoteaser-close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 26px;
  height: 26px;
  display: block;
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
  margin-top: 0;
}
.infoteaser-close:focus, .infoteaser-close.focus {
  outline-color: #adb5bd;
}
.infoteaser-close::before, .infoteaser-close::after {
  content: "";
  position: absolute;
  left: 12px;
  top: 0;
  height: 26px;
  width: 1px;
  background-color: #000000;
}
.infoteaser-close::before {
  transform: rotate(45deg);
}
.infoteaser-close::after {
  transform: rotate(-45deg);
}

@keyframes infoIn {
  0% {
    opacity: 0;
    transform: scale(0.8) translate3d(-3.5rem, 2.5rem, 0);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}
@keyframes infoOut {
  0% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
    transform: scale(0.8) translate3d(-3.5rem, 2.5rem, 0);
  }
}
.footer .footer-top-wrapper .footer-navigation .list-inline {
  margin-top: 50px;
}
.footer .footer-top-wrapper .footer-navigation .list-inline .list-inline-item {
  margin-bottom: 30px;
  line-height: 17px;
  display: block;
}
.footer .footer-top-wrapper .footer-navigation .list-inline .list-inline-item:last-of-type {
  margin-bottom: 0;
}
.footer .footer-top-wrapper .footer-navigation .navigation-link {
  font-family: "GT America", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 17px;
  letter-spacing: 2px;
  text-decoration: none;
}
.footer .footer-top-wrapper .footer-navigation .navigation-link:hover {
  text-decoration: underline;
}
.footer .footer-top-wrapper .footer-sociallinks {
  margin: 10px 0 30px;
  padding-top: 30px;
}
.footer .footer-top-wrapper .footer-sociallinks .social-links {
  max-width: 137px;
  width: 100%;
  margin: 0 auto 30px;
  display: flex;
  justify-content: space-around;
}
.footer .footer-top-wrapper .footer-sociallinks .social-links .list-inline-item {
  margin: 0;
  display: flex;
  align-items: center;
}
.footer .footer-top-wrapper .footer-sociallinks .social-links .list-inline-item a {
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 1024px) {
  .footer .footer-top-wrapper {
    display: flex;
  }
  .footer .footer-top-wrapper .footer-navigation {
    padding: 20px 30px;
    border-right: solid 2px #000000;
    width: 100%;
  }
  .footer .footer-top-wrapper .footer-navigation .list-inline {
    justify-content: space-between;
    display: flex;
  }
  .footer .footer-top-wrapper .footer-navigation .list-inline,
.footer .footer-top-wrapper .footer-navigation .list-inline-item {
    margin: 0 !important;
  }
  .footer .footer-top-wrapper .footer-sociallinks {
    margin: 0;
    display: flex;
    justify-content: center;
    width: 140px;
    padding-top: 0;
  }
  .footer .footer-top-wrapper .footer-sociallinks .social-links {
    margin: 0;
    width: 100px;
  }
}
@media (min-width: 1440px) {
  .footer .footer-top-wrapper .footer-navigation {
    padding: 20px 60px;
  }
  .footer .footer-top-wrapper .footer-sociallinks {
    width: 260px;
  }
  .footer .footer-top-wrapper .footer-sociallinks .social-links {
    width: 140px;
  }
}
@media (min-width: 1700px) {
  .footer .footer-top-wrapper .footer-navigation .list-inline {
    max-width: 1330px;
  }
  .footer .footer-top-wrapper .footer-sociallinks {
    width: 310px;
    flex-shrink: 0;
  }
  .footer .footer-top-wrapper .footer-sociallinks .social-links {
    width: 200px;
    max-width: 200px;
  }
}
.footer .footer-bottom-wrapper {
  text-transform: uppercase;
  font-family: "GT America", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 2px;
}
.footer .footer-bottom-wrapper .list-inline {
  margin: 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer .footer-bottom-wrapper .list-inline .list-inline-item:not(:last-child) {
  margin-right: 20px;
}
.footer .footer-bottom-wrapper .list-inline .navigation-link {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 2px;
  text-decoration: none;
}
.footer .footer-bottom-wrapper .list-inline .navigation-link:hover {
  text-decoration: underline;
}
.footer .footer-bottom-wrapper .footer-contact {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.footer .footer-bottom-wrapper .footer-contact .contact {
  display: flex;
}
.footer .footer-bottom-wrapper .footer-contact .contact .dot {
  margin: 0 5px;
  display: block;
}
@media (min-width: 1025px) {
  .footer .footer-bottom-wrapper {
    display: flex;
    flex-direction: row-reverse;
    padding: 10px 30px;
    justify-content: space-between;
  }
  .footer .footer-bottom-wrapper .footer-metanavigation {
    border-bottom: none !important;
    max-width: 210px;
  }
  .footer .footer-bottom-wrapper .footer-contact {
    height: unset;
    flex-direction: row;
  }
  .footer .footer-bottom-wrapper .footer-contact .contact {
    margin-left: 10px;
  }
  .footer .footer-bottom-wrapper .list-inline {
    height: unset;
  }
}
@media (min-width: 1440px) {
  .footer .footer-bottom-wrapper {
    padding: 10px 60px;
  }
}
@media (min-width: 1440px) {
  .footer .footer-bottom-wrapper {
    padding: 10px 50px 10px 60px;
  }
}

.swatch-primary {
  color: #ffffff;
  background-color: #8D724E;
}

.swatch-secondary {
  color: #ffffff;
  background-color: #6c757d;
}

.swatch-success {
  color: #ffffff;
  background-color: #28a745;
}

.swatch-info {
  color: #ffffff;
  background-color: #17a2b8;
}

.swatch-warning {
  color: #212529;
  background-color: #ffc107;
}

.swatch-danger {
  color: #ffffff;
  background-color: #b60202;
}

.swatch-light {
  color: #212529;
  background-color: #EAE7E1;
}

.swatch-dark {
  color: #212529;
  background-color: #CFBFA8;
}

.swatch-bronze {
  color: #ffffff;
  background-color: #8D724E;
}

.swatch-beige-dark {
  color: #212529;
  background-color: #CFBFA8;
}

.swatch-beige-light {
  color: #212529;
  background-color: #EAE7E1;
}

.swatch-mint {
  color: #212529;
  background-color: #C3CFAC;
}

.swatch-red {
  color: #ffffff;
  background-color: #b60202;
}

@media (min-width: 325px) and (max-width: 576px) {
  .row {
    margin-left: -30px;
    margin-right: -30px;
  }

  .container,
.row > .col,
.row > [class*=col-] {
    padding-right: 30px;
    padding-left: 30px;
  }
}
.skiplinks .sr-only:focus {
  display: inline-block;
  padding: 0.5rem;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  background-color: #ffffff;
  z-index: 1070;
}

.detail-backlink-wrap {
  padding: 20px 20px 10px;
  display: inline-block;
}
@media (min-width: 1025px) {
  .detail-backlink-wrap {
    padding: 30px 30px 50px;
  }
}

.container .container {
  padding-left: 0;
  padding-right: 0;
}

.footer {
  /*    &-content {
          background-color: $gray-700;
          color: $white;
      }*/
}
.footer-sociallinks {
  font-size: 2rem;
}

.scroll-top {
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  z-index: 1020;
  background-color: #495057;
  color: #ffffff;
  bottom: 20px;
  right: 20px;
  position: fixed;
  opacity: 0;
  width: 3em;
  height: 3em;
}
.scroll-top:active, .scroll-top:focus, .scroll-top:hover {
  color: #ffffff;
  background-color: #8D724E;
}
.css-arrow, .scroll-top .scroll-top-icon {
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.css-arrow:before, .scroll-top .scroll-top-icon:before {
  content: "";
  position: absolute;
  height: 30%;
  width: 30%;
  border-top: 1px solid;
  border-left: 1px solid;
  top: 55%;
  left: 55%;
  transform: translate(-50%, -50%);
}

picture {
  display: block;
}

.btn-position-left {
  float: left;
}

.btn-position-right {
  float: right;
}

.frame li,
.fullwidth-frame li {
  margin-bottom: 1rem;
}

.img-rounded {
  border-radius: 100%;
}

.desc,
.desc p,
.img-desc,
.img-desc p {
  text-transform: uppercase;
  text-align: center;
  font-family: "GT America", sans-serif;
  letter-spacing: 2px;
  line-height: 1.67;
}
@media (max-width: 1024.98px) {
  .desc,
.desc p,
.img-desc,
.img-desc p {
    font-size: 0.75rem;
  }
}

section.newsletter {
  border-top: 2px solid #000000;
}

.newsletter-form {
  width: 500px;
  max-width: 100%;
}
.newsletter-form form {
  display: flex;
  padding: 8px;
  border: 1px solid #000000;
  justify-content: space-between;
}
.newsletter-form form input[type=text] {
  border: none;
  flex: 1;
  padding-left: 10px;
  padding-right: 10px;
}
.newsletter-form form input[type=text]:focus {
  outline: none;
}
@media (max-width: 767.98px) {
  .newsletter-form form input[type=text] {
    width: 100%;
    max-width: 250px;
  }
}

.no-scrolling {
  overflow: hidden;
}

.textmedia-gallery .video {
  margin-left: -20px;
  margin-right: -20px;
}

.embed-responsive-16by9 {
  background: black;
  position: relative;
}
.embed-responsive-16by9 .btn-control-video {
  position: absolute;
  padding: 0;
  right: 2%;
  bottom: 5%;
  background: transparent;
  border: 0;
}
.embed-responsive-16by9 .btn-control-video:hover, .embed-responsive-16by9 .btn-control-video:focus {
  color: #ffffff;
}
@media (min-width: 1025px) {
  .embed-responsive-16by9 .btn-control-video {
    bottom: 5%;
    right: 2rem;
    top: initial;
  }
}