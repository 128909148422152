@import "variables";
//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

/* NEWS */

.news {
    // News Detailseite
    &.news-single {
        /*  .category-wrapper {
            margin-bottom: 20px;

            @include media-breakpoint-up(lg) {
              margin-bottom: 30px;
            }
          }
      */
        .header h1 {
            padding-bottom: 0;
        }

        .teaser-text p {
            font-size: $font-size-base;
            line-height: 1.6;
            margin-bottom: 0;

            @include media-breakpoint-up(lg) {
                font-size: $font-size-big
            }
        }

        @include media-breakpoint-up(lg) {
            .tab-content .bodytext:not(:first-of-type) {
                border-left: 1px solid black;
            }
        }
    }
}
