// Footer

.footer {
    .footer-top-wrapper {
        .footer-navigation {
            .list-inline {
                margin-top: 50px;

                .list-inline-item {
                    margin-bottom: 30px;
                    line-height: 17px;
                    display: block;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }

            .navigation-link {
                font-family: $font-family-sans-serif;
                font-size: 0.875rem;
                font-weight: $font-weight-medium;
                text-transform: uppercase;
                line-height: 17px;
                letter-spacing: 2px;
                text-decoration: none;

                @include hover() {
                    text-decoration: underline;
                }
            }
        }

        .footer-sociallinks {
            margin: 10px 0 30px;
            padding-top: 30px;

            .social-links {
                max-width: 137px;
                width: 100%;
                margin: 0 auto 30px;
                display: flex;
                justify-content: space-around;

                .list-inline-item {
                    margin: 0;
                    display: flex;
                    align-items: center;

                    a {
                        display: flex;
                        width: 30px;
                        height: 30px;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }

        @media screen and (min-width: 1024px) {
            display: flex;

            .footer-navigation {
                padding: 20px 30px;
                border-right: solid 2px $black;
                width: 100%;

                .list-inline {
                    justify-content: space-between;
                    display: flex;
                }

                .list-inline,
                .list-inline-item {
                    margin: 0 !important;
                }
            }

            .footer-sociallinks {
                margin: 0;
                display: flex;
                justify-content: center;
                width: 140px;
                padding-top: 0;

                .social-links {
                    margin: 0;
                    width: 100px;
                }
            }
        }

        @media (min-width: 1440px) {
            .footer-navigation {
                padding: 20px 60px;
            }

            .footer-sociallinks {
                width: 260px;

                .social-links {
                    width: 140px;
                }
            }
        }

        @media (min-width: 1700px) {
            .footer-navigation {
                .list-inline {
                    max-width: 1330px;
                }
            }

            .footer-sociallinks {
                width: 310px;
                flex-shrink: 0;

                .social-links {
                    width: 200px;
                    max-width: 200px;
                }
            }
        }
    }

    .footer-bottom-wrapper {
        text-transform: uppercase;
        font-family: $font-family-sans-serif;
        font-size: $font-size-smaller;
        font-weight: $font-weight-regular;
        line-height: 20px;
        letter-spacing: 2px;

        .list-inline {
            margin: 0;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;

            .list-inline-item:not(:last-child) {
                margin-right: 20px;
            }

            .navigation-link {
                font-size: $font-size-smaller;
                font-weight: $font-weight-regular;
                line-height: 20px;
                letter-spacing: 2px;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .footer-contact {
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .contact {
                display: flex;

                .dot {
                    margin: 0 5px;
                    display: block;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            display: flex;
            flex-direction: row-reverse;
            padding: 10px 30px;
            justify-content: space-between;

            .footer-metanavigation {
                border-bottom: none !important;
                max-width: 210px;
            }

            .footer-contact {
                height: unset;
                flex-direction: row;

                .contact {
                    margin-left: 10px;
                }
            }

            .list-inline {
                height: unset;
            }
        }

        @media (min-width: 1440px) {
            padding: 10px 60px;
        }

        @media (min-width: 1440px) {
            padding: 10px 50px 10px 60px;
        }
    }
}
