.abo-header-container {
    width: 100%;
    height: 100%;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.abo-header-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .abo-header-title {
        border-top: 2px solid $black;
        border-bottom: 2px solid $black;
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
        width: 100%;


        h3 {
            color: $black;
            text-align: center;
            padding-bottom: 0;
        }
    }

    .abo-header-text {
        text-align: center;
    }

    .abo-header-price {
        font-size: 1rem;
        font-family: $font-family-sans-serif;
        font-weight: 500;
        color: $bronze;
        letter-spacing: 2.67px;
        line-height: 1.25;
        text-align: center;
    }
}

.handpicked-container {
    max-width: 80%;
}

@media screen and (min-width: 576px) and (max-width: 1360px) {
    .abo-header-content {
        .abo-header-price {
            text-align: left;

            span {
                display: block;
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .abo-header-content {
        .abo-header-price {
            font-size: 0.9rem;
        }
    }
}

@media screen and (max-width: 1180px) {
    .abo-header-container {
        .second-h1 {
            font-size: 3.125rem;
        }

        .big {
            font-size: 1.25rem;
        }
    }
}

@media screen and (max-width: 1025px) {
    .abo-header-container {
        max-width: 90%;
    }

    .handpicked-container {
        max-width: 100%;
    }
    .abo-header-logo-wrapper {
        svg {
            width: 140px;
            height: 127px;
        }
    }
}

@media screen and (max-width: 768px) {
    .abo-header-container {
        .second-h1 {
            font-size: 2.187rem;
        }

        .big {
            font-size: 1rem;
        }

        p.small {
            font-size: 0.75rem;
        }
    }
}

@media screen and (max-width: 576px) {
    .abo-header-container {
        .second-h1 {
            padding-bottom: 0.625rem;
        }

        .btn.btn-primary {
            margin-top: 0 !important;
        }
    }
}

@media screen and (max-width: 325px) {
    .abo-header-img {
        padding-bottom: 140px;
        background: #f9f8f4;
    }
}

.frame-type-wvier_abo_header .scrolldown {
    cursor: pointer;
    position: absolute;
    z-index: 1010;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media screen and (max-width: 1024px) {
    .frame-type-wvier_abo_header .scrolldown {
        display: none;
    }
}

@media screen and (min-width: 576px) {
    .abo-header {
        max-height: calc(100vh - 120px);
        overflow: hidden;
    }
}
