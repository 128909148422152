// Cards / Accordion
@import "variables";
//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/card";

//
// card
//
.card-img-top {
    overflow: hidden;

    picture,
    img {
        width: 100%;
        height: auto;
    }
}

.card-body {
    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

//
// Accordion
//
.accordion > .card > .card-header {
    margin-bottom: 0;
}

.accordion .card-body {
    padding: 0;
}

.accordion > .card:last-of-type {
    border-bottom: 2px solid $black;
}

.accordion-item {
    //border-bottom: $card-border-width solid $card-border-color !important;
    background-color: transparent;
    border: none;
}

/*.accordion-item + .accordion-item {
    margin-top: 0.25rem;
}*/

.accordion-header {
    padding: 0;
    background-color: transparent;
    border: none;
    text-align: center;
    //margin-bottom: 0;

    .btn-link {
        position: relative;
        width: 100%;
        //padding: $card-spacer-y calc(#{$card-spacer-x} + 2em) $card-spacer-y $card-spacer-x;
        border: none;
        border-top: 2px solid $bronze;
        padding-top: 35px;
        padding-bottom: 35px;
        padding-left: 0;
        padding-right: 0;
        //margin-bottom: 0;
        text-align: center;
        background-color: transparent;
        color: $bronze;
        margin-top: 0 !important;

        @include media-breakpoint-down(md) {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        &:hover,
        &:focus {
            text-decoration: none;
        }

        .accordion-header-text {
            @extend h2;

            color: $bronze;
            margin-bottom: 0;

            @include media-breakpoint-down(md) {
                font-size: $font-size-smaller;
            }
        }
    }

    .accordion-header-icon {
        position: absolute;
        height: 24px;
        width: 24px;
        top: 50%;
        right: $card-spacer-x;
        transform: translate(0, -50%);

        @include media-breakpoint-down(md) {
            right: 0;
            width: 12px;
            height: 12px;
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            display: block;
            height: 2px;
            width: 100%;
            background-color: $bronze;
            transition: all 0.2s ease-in-out;
        }

        &:after {
            transform: rotate(0);
            background-color: $bronze;
        }
    }

    .btn-link.collapsed {
        border-top: 2px solid $black;

        .accordion-header-icon {
            &:after,
            &:before {
                background-color: $black;
            }

            &:after {
                transform: rotate(90deg);
            }
        }

        .accordion-header-text {
            color: $black;
        }
    }
}

.accordion-content {
    display: flex;
    flex-wrap: wrap;
    margin-left: -($card-spacer-x / 2);
    margin-right: -($card-spacer-x / 2);
    padding-top: 15px;
    padding-bottom: 50px;
}

.accordion-content-item {
    flex-grow: 1;
    order: 1;
    padding-left: ($card-spacer-x / 2);
    padding-right: ($card-spacer-x / 2);
    width: 100%;
}

.accordion-content-text {
    *:last-child {
        margin-bottom: 0;
    }
}

/*.accordion-body {
    border-top: $card-border-width solid $card-border-color;
}*/

// - Position
.accordion-content-right,
.accordion-content-left {
    .accordion-content-item {
        @include media-breakpoint-up(md) {
            width: 50%;
            *:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.accordion-content-top,
.accordion-content-left {
    .accordion-content-gallery {
        margin-bottom: $card-spacer-x;
    }
}

.accordion-content-left {
    .accordion-content-gallery {
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }
}

.accordion-content-bottom,
.accordion-content-right {
    .accordion-content-gallery {
        margin-top: $card-spacer-x;
    }
}

.accordion-content-right {
    .accordion-content-media {
        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
    }
}

//
// Card Group Element
//
.card-group-element {
    margin: -$card-deck-margin;
    margin-bottom: -$card-deck-margin !important;
    display: flex;
    flex-wrap: wrap;
}

.card-group-element-item {
    width: 100%;
    padding: $card-deck-margin;

    .card {
        height: 100%;
    }

    .card-title {
        font-size: $card-title-size;
        font-weight: $font-weight-bold;
        margin-bottom: $card-spacer-y;
    }

    .card-subtitle {
        font-size: $card-subtitle-size;
        font-weight: $font-weight-bold;
        margin-bottom: $card-spacer-y;
    }

    .card-title + .card-subtitle {
        margin-top: -$card-spacer-y;
    }

    .card-footer {
        border-top: 0;
        padding: $card-spacer-x;
        background-color: transparent;
    }
}

.card-group-element-columns-2 {
    .card-group-element-item {
        @include media-breakpoint-up('sm') {
            width: calc(100% / 2);
        }
    }
}

.card-group-element-columns-3 {
    .card-group-element-item {
        @include media-breakpoint-up('md') {
            width: calc(100% / 3);
        }
    }
}

.card-group-element-columns-4 {
    .card-group-element-item {
        @include media-breakpoint-up('sm') {
            width: calc(100% / 2);
        }
        @include media-breakpoint-up('xl') {
            width: calc(100% / 4);
        }
    }
}

/*** numbered accordion ******/
.numbered-accordion {
    counter-reset: section;

    .accordion-header {
        .btn-link.collapsed::before {
            color: $black;
        }

        .btn-link::before {
            position: absolute;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            left: 1.25rem;
            font-size: 14px;
            counter-increment: section;
            content: counter(section, decimal-leading-zero) ".";

            @include media-breakpoint-down(md) {
                left: 0;
                align-items: flex-start;
                padding-top: 20px;
                font-size: 12px;
            }
        }

        .accordion-header-text {
            text-align: left;
            font-size: $font-size-base;

            @include media-breakpoint-down(md) {
                font-size: $font-size-smaller;
            }
        }

    }
}
