.table-bordered {
    border: none;

    thead th {
        @extend .h2;
        padding: 50px 0;
        border: none;
    }

    tr td:first-of-type {
        border-left: none;
        padding-right: 5px;
    }

    tr td:last-of-type {
        border-right: none;
        padding-left: 5px;
    }

    td {
        padding: 20px 0;

        p {
            margin-bottom: 0;
        }
    }
}
