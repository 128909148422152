@import "variables";
//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
//Bootstrap Optional
//@import "bootstrap/scss/root";
//@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
//@import "bootstrap/scss/code";
//@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
//@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
//@import "bootstrap/scss/dropdown";
//@import "bootstrap/scss/button-group";
//@import "bootstrap/scss/input-group";
//@import "bootstrap/scss/custom-forms";
//@import "bootstrap/scss/nav";
//@import "bootstrap/scss/navbar";
//@import "bootstrap/scss/card";
@import "bootstrap/scss/breadcrumb";
//@import "bootstrap/scss/pagination";
//@import "bootstrap/scss/badge";
//@import "bootstrap/scss/jumbotron";
//@import "bootstrap/scss/alert";
//@import "bootstrap/scss/progress";
//@import "bootstrap/scss/media";
@import "bootstrap/scss/list-group";
//@import "bootstrap/scss/close";
//@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
//@import "bootstrap/scss/tooltip";
//@import "bootstrap/scss/popover";
//@import "bootstrap/scss/carousel";
//@import "bootstrap/scss/spinners";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/print";
//Bootstrap Custom
@import "BootstrapCustom/type";
//@import "BootstrapCustom/buttons";
// Custom Styles
// Gallery
//@import "Libraries/Scss/slick";
////@import "Libraries/Scss/slick-theme";
//@import "dpcookieconsent";
//@import "navbar";
//@import "frame";
//@import "hero-textpic";
//@import "textpic";
//@import "textpic-fullwidth";
//@import "texticon";
//@import "gallery";
@import "magnific";
@import "tables";
@import "uploads";
@import "cards";
//@import "quote";
//@import "team";
//@import "rounded-image";
//@import "images-two-col";
@import "abo-header";
@import "downloads";
//@import "tabs";
//@import "teaser";
//@import "teaser-grid";
//@import "room-details";
//@import "locations";
//@import "text-slider";
@import "slider";
//@import "banner";
@import "news";
//@import "packages";
@import "infoteaser";
@import "footer";

@each $color, $value in $theme-colors {
    .swatch-#{$color} {
        color: color-yiq($value);
        background-color: $value;
    }
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
    .row {
        margin-left: -30px;
        margin-right: -30px;
    }

    .container,
    .row > .col,
    .row > [class*="col-"] {
        padding-right: 30px;
        padding-left: 30px;
    }
}

// Skiplinks
.skiplinks {
    .sr-only {
        &:focus {
            display: inline-block;
            padding: 0.5rem;
            width: auto;
            height: auto;
            overflow: visible;
            clip: auto;
            white-space: normal;
            background-color: $white;
            z-index: $zindex-tooltip;
        }
    }
}

// Back-Link on Newsdetail and Packagedetail
.detail-backlink-wrap {
    padding: 20px 20px 10px;
    display: inline-block;

    @include media-breakpoint-up(lg) {
        padding: 30px 30px 50px;
    }
}

// Layout
.container {
    .container {
        padding-left: 0;
        padding-right: 0;
    }
}

// Footer
.footer {
    /*    &-content {
            background-color: $gray-700;
            color: $white;
        }*/

    &-sociallinks {
        font-size: 2rem;
    }
}

//
// Scrolltop
//
.scroll-top {
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    z-index: $zindex-sticky;
    background-color: $gray-700;
    color: $white;
    bottom: ($grid-gutter-width / 2);
    right: ($grid-gutter-width / 2);
    position: fixed;
    opacity: 0;
    width: 3em;
    height: 3em;

    &:active,
    &:focus,
    &:hover {
        color: $white;
        background-color: $primary;
    }

    .scroll-top-icon {
        @extend .css-arrow;
    }

}

.css-arrow {
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    &:before {
        content: '';
        position: absolute;
        height: 30%;
        width: 30%;
        border-top: 1px solid;
        border-left: 1px solid;
        top: 55%;
        left: 55%;
        transform: translate(-50%, -50%);
    }
}

picture {
    display: block;
}

.btn-position-left {
    float: left;
}

.btn-position-right {
    float: right;
}

.frame li,
.fullwidth-frame li {
    margin-bottom: 1rem;
}

.img-rounded {
    border-radius: 100%;
}

.desc,
.desc p,
.img-desc,
.img-desc p {
    @extend .smaller;

    text-transform: uppercase;
    text-align: center;
    font-family: $font-family-sans-serif;
    letter-spacing: 2px;
    line-height: 1.67;

    @include media-breakpoint-down(md) {
        font-size: $font-size-smallest;
    }
}

// Newsletter Form
section.newsletter {
    border-top: 2px solid $black;
}

.newsletter-form {
    width: 500px;
    max-width: 100%;

    form {
        display: flex;
        padding: 8px;
        border: 1px solid $black;
        justify-content: space-between;

        input[type="text"] {
            border: none;
            flex: 1;
            padding-left: 10px;
            padding-right: 10px;
        }

        input[type="text"]:focus {
            outline: none;
        }

        input.btn.btn-primary {
            @extend .mt-0;
        }

        @include media-breakpoint-down(sm) {
            input[type="text"] {
                width: 100%;
                max-width: 250px;
            }
        }
    }
}



.no-scrolling {
    overflow: hidden;
}

.textmedia-gallery {
    .video {
        margin-left: -20px;
        margin-right: -20px;
    }
}

.embed-responsive-16by9 {
    background: black;
    position: relative;


    .btn-control-video {
        position: absolute;
        padding: 0;
        right: 2%;
        bottom: 5%;
        background: transparent;
        border: 0;

        &:hover,
        &:focus {
            color: $white;
        }

        @include media-breakpoint-up(lg) {
            bottom: 5%;
            right: 2rem;
            top: initial;
        }
    }
}
