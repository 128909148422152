.downloads {
    .download {
        display: flex;
        align-items: center;

        img {
            width: 100px;
            height: 100px;

            @include media-breakpoint-down(lg) {
                width: 85px;
                height: 85px;
            }

            @include media-breakpoint-down(sm) {
                width: 70px;
                height: 70px;
            }
        }

        a {
            font-size: $font-size-base;
            text-decoration: none;

            @include media-breakpoint-down(lg) {
                font-size: $font-size-small;
            }

            @include media-breakpoint-down(sm) {
                font-size: $font-size-smaller;
            }

            .download-title {
                font-family: $font-family-sans-serif;
                text-transform: uppercase;
            }

            span {
                display: block;
            }

            &:hover {
                .download-title {
                    color: $bronze;
                }

                .download-file {
                    text-decoration: underline;
                }
            }

            @include media-breakpoint-down(md) {
                .download-file {
                    text-decoration: underline;
                }
            }
        }
    }
}
