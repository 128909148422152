
.btn.infoteaser-btn {
    position: fixed;
    left: 10px;
    bottom: 10px;
    display: block;
    width: 65px;
    height: 65px;
    padding: 6px;
    background: $beige-light;
    border: 0;
    border-radius: 50%;
    color: $body-color;
    box-shadow: 2px 2px 4px 2px rgba($black, 0.15);
    z-index: 900;
    appearance: none;
    pointer-events: auto;

    &:hover {
        background-color: $beige-dark;
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
        background-color: $beige-light;
        box-shadow: 2px 2px 4px 2px rgba($black, 0.15);

        &:hover {
            background-color: $beige-dark;
        }
    }

    &:active,
    &.active {
        background-color: $beige-light;
        box-shadow: 2px 2px 4px 2px rgba($black, 0.15);

        &:hover {
            background-color: $beige-dark;
        }
    }


    &:focus,
    &.focus {
        outline-color: $beige-light;
    }

    .svg-icon {
        width: 100%;
        height: 100%;
    }

    @include media-breakpoint-up(lg) {
        width: 80px;
        height: 80px;
        padding: 8px;
        bottom: 10px;
    }
}

.infoteaser-items-wrapper {
    position: fixed;
    display: flex;
    align-items: flex-end;
    max-width: 350px;
    top: 10px;
    right: 10px;
    left: 10px;
    bottom: 90px;
    z-index: 10000;
    pointer-events: none;

    &.show-it {
        .infoteaser-inner {
            animation-name: infoIn;
            animation-duration: 250ms;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
        }
    }

    &.hide-it {
        .infoteaser-inner {
            animation-name: infoOut;
            animation-duration: 250ms;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
        }
    }


    .infoteaser-inner {
        position: relative;
        padding: 3.25rem 1.25rem 3rem;
        width: 100%;
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        box-shadow: 2px 2px 4px 2px rgba($black, 0.15);
        background-color: $beige-dark;
        pointer-events: auto;

        .infoteaser-item {
            text-align: center;

            .infoteaser-title {
                font-family: $font-family-serif;
                text-transform: none;
                font-size: $font-size-base;
                font-weight: 400;
                margin-bottom: 0;

                @include media-breakpoint-up(lg) {
                    font-size: $font-size-big;
                }
            }
        }
    }

    @include media-breakpoint-up(sm) {
        right: auto;
        max-width: 490px;
    }

    @include media-breakpoint-up(lg) {
        left: 25px;
        bottom: 105px;

        .infoteaser-inner {
            padding: 3.25rem 2.25rem 2.25rem;
        }
    }
}

.infoteaser-close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 26px;
    height: 26px;
    display: block;
    padding: 0;
    background-color: transparent;
    border: 0;
    appearance: none;
    margin-top: 0;

    &:focus,
    &.focus {
        outline-color: $gray-500;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        left: 12px;
        top: 0;
        height: 26px;
        width: 1px;
        background-color: $black;
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
}

@keyframes infoIn{
    0%{
        opacity: 0;
        transform: scale(0.8) translate3d(-3.5rem, 2.5rem, 0);
    }
    100%{
        opacity: 1;
        transform: scale(1) translate3d(0, 0, 0);
    }
}

@keyframes infoOut{
    0%{
        opacity: 1;
        transform: scale(1) translate3d(0, 0, 0);

    }
    100%{
        opacity: 0;
        transform: scale(0.8) translate3d(-3.5rem, 2.5rem, 0);
    }
}
