.slick-arrow {
    width: 90px;
    height: 90px;
    border-radius: 100%;
    z-index: 100;
    transition: all 250ms ease-in-out;
}

.slick-arrow:hover {
    background: rgba(0, 0, 0, 0.5);
}

.slick-prev:before, .slick-next:before {
    opacity: 1;
    font-size: 40px;
}
