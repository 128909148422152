// stylelint-disable selector-list-comma-newline-after

//
// Headings
//
h1, .h1 {
    display: block;
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-regular;
    font-size: $h1-font-size;
    line-height: 1.3;
    letter-spacing: 2px;
    text-transform: uppercase;
    //margin-bottom: $headings-margin-bottom * 1.375;
    padding-bottom: 1.875rem;

    @include media-breakpoint-up(lg) {
        font-size: $h1-font-size-lg;
        //margin-bottom: $headings-margin-bottom * 1.5;
        padding-bottom: 3.125rem;
    }
}

.second-h1,
.hero-h1 {
    display: block;
    font-family: $font-family-serif;
    font-weight: $font-weight-regular;
    font-size: $hero-h1-font-size;
    line-height: 1.3;
    letter-spacing: 0.5px;
    //margin-bottom: $headings-margin-bottom * 1.375;
    text-transform: initial;

    @include media-breakpoint-up(md) {
        font-size: $hero-h1-font-size-md;
    }

    @include media-breakpoint-up(lg) {
        font-size: $hero-h1-font-size-lg;
        margin-bottom: $headings-margin-bottom * 1.5;
        line-height: 1;
    }
}

h2, .h2 {
    display: block;
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-regular;
    font-size: $h2-font-size;
    line-height: 1.33;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding-bottom: 1.875rem;
    //margin-bottom: $headings-margin-bottom * 1.375;

    @include media-breakpoint-up(lg) {
        font-size: $h2-font-size-lg;
        padding-bottom: 1.875rem;
        //margin-bottom: $headings-margin-bottom * 1.5;
    }
}

.second-h2 {
    display: block;
    font-family: $font-family-serif;
    font-weight: $font-weight-regular;
    font-size: $second-h2-font-size;
    line-height: 1.19;
    letter-spacing: 0.5px;
    //margin-bottom: $headings-margin-bottom * 1.375;
    text-transform: initial;
    padding-bottom: 1.875rem; //30px

    @include media-breakpoint-up(lg) {
        font-size: $second-h2-font-size-lg;
        //margin-bottom: $headings-margin-bottom * 1.5;
    }
}

h3, .h3 {
    display: block;
    font-family: $font-family-serif;
    font-weight: $font-weight-bold;
    font-size: $h3-font-size;
    line-height: 1.75;
    letter-spacing: 0.5px;
    margin-bottom: $headings-margin-bottom * 1.25;

    @include media-breakpoint-up(lg) {
        font-size: $h3-font-size-lg;
        margin-bottom: $headings-margin-bottom;
    }
}

.second-h3 {
    display: block;
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-medium;
    font-size: $second-h3-font-size;
    line-height: 1.43;
    letter-spacing: 4px;
    text-transform: uppercase;
    padding-bottom: 1.875rem; //30px;
    color: $bronze;
}

h4, .h4 {
    display: block;
    font-size: $h4-font-size;
    margin-bottom: $headings-margin-bottom * 0.625;
    line-height: 1.166667;

    @include media-breakpoint-up(lg) {
        font-size: $h4-font-size-lg;
        line-height: 1.153846;
    }
}

.second-h4 {
    display: block;
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-medium;
    font-size: $h4-font-size;
    line-height: 1.166667;
    letter-spacing: 4px;
    text-transform: uppercase;
    margin-bottom: $headings-margin-bottom * 1.375;
    color: $bronze;
}

h5, .h5 {
    display: block;
    font-size: $h5-font-size;
    margin-bottom: $headings-margin-bottom * 0.625;
    line-height: 1.18181818;
}

h6, .h6 {
    display: block;
    font-size: $h6-font-size;
    margin-bottom: $headings-margin-bottom * 0.25;
    line-height: $line-height-sm;

    @include media-breakpoint-up(lg) {
        line-height: $line-height-base;
    }
}

p {
    letter-spacing: $letter-spacing-base;
    font-size: $font-size-small;

    @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
    }
}

ul li,
ol li {
    letter-spacing: $letter-spacing-base;
    font-size: $font-size-small;

    @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
    }
}

.smaller,
.small,
.big {
    font-family: $font-family-serif;
    font-weight: $font-weight-regular;
    letter-spacing: 0.5px;
}

.smaller {
    font-size: $font-size-smaller;
    line-height: 20px;
}

small,
.small {
    font-size: $font-size-small;
    line-height: 1.5625;
}

.big {
    font-size: $font-size-base;
    line-height: 1.6;

    @include media-breakpoint-up(lg) {
        font-size: $font-size-big
    }
}


//
// Lists
//

ul,
ol {
    padding-left: 1.125rem;
}

.list-unstyled {
    @include list-unstyled();
}

// Inline turns list items into inline-block
.list-inline {
    @include list-unstyled();
}

.list-inline-item {
    display: inline-block;

    &:not(:last-child) {
        margin-right: $list-inline-padding;
    }
}




